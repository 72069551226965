<template>
  <section class="tutoriales">
    <!-- <div :class="{
      bg_opacity: show_video_page
    }"/> -->
    <div class="row justify-content-between align-items-center b-bottom">
      <h3 class="mb-0">Tutoriales Databot</h3>
      <div class="d-flex align-items-center">
        <span class="text_sm mr-4">Buscar en módulo: {{ selected_module }}</span>
        <div class="input_box">
          <span class="btn-date px-2">
            <i class="fas fa-search mr-0" />
          </span>
          <input
            type="search"
            class="input_search sm mb-0"
            placeholder="Buscar"
            v-model="search_video"
          />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-2 my-5">
        <div class="pl-4">
          <label class="mb-4">Módulos del sistema</label>
          <ul class="pl-0">
            <li
              v-for="(module, index) in modules"
              :key="index"
              @click="selected_module = module"
              :class="{
                'category mb-3': true,
                active: selected_module == module
              }"
            >
              {{ module }}
              <span>{{ videosPerModule(module) }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div :class="{'col-lg-10 pr-0': true, 'my-5': searchedChat && searchedChat.length > 0 }">
        <div v-if="searchedChat && searchedChat.length > 0" class="row mobile-center">
          <div v-for="(video, index) in searchedChat" :key="index" class="p-relative mr-5 mb-5">
            <SpinnerLoading v-show="loading" />
            <FadeTransition v-show="!loading" :duration="200" :key="video.id">
              <div class="item">
                <!-- <router-link :to="{ query: { postId: video.id } }">
                </router-link> -->
                <span class="i-btn i-btn-sm i-expand p-custom e-hide mr-0" @click="setSelectedVideo(video)" />
                <span class="d-block text_sm normal mb-1">{{ video.page.properties.module.select.name }}</span>
                <h2 class="title mb-4">{{ video.page.properties.title.title[0].text.content }}</h2>
                <template v-if="video.page.properties.url_iframe">
                  <iframe
                    @load="loading = false"
                    width="330"
                    height="185"
                    class="iframe_box mb-2"
                    :src="video.page.properties.url_iframe.url"
                    :title="video.page.properties.title.title[0].text.content"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />
                </template>
                <p>{{ video.page.properties.description.rich_text[0].text.content }}</p>
              </div>
            </FadeTransition>            
          </div>
        </div>
        <template v-else>
          <FadeTransition :duration="500" :key="searchedChat.length">
            <div class="no_info">
              <img class="no_video mb-3" src="/img/icons/38-no-videos.svg" alt="">
              <span>No se encontraron videos relacionados</span>
            </div>
          </FadeTransition>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import chatbot_api from "@/chatbot_api";
import { mapState, mapMutations } from "vuex";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition,
  },
  data() {
    return {
      loading: true,
      videos: [],
      blog: [],
      selected_module: "Todos",
      search_video: "",
    }
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    ...mapState ["selected_bot"],

    searchedChat() {
      let items = this.filterByModule;

      if (!this.search_video) return items;

      return items.filter((el) => {
        let name = el.page.properties.module.select.name;
        let question = el.page.properties.title.title[0].text.content

        return [name, question].find((field) => {
          return field
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              this.search_video
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim(),
            );
        });
      });
    },
    filterByModule() {
      if (this.selected_module == "Todos") return this.videos;

      return this.videos.filter(el => el.page.properties.module.select.name == this.selected_module);
    },
    modules() {
      // Total de categorías ordenadas
      let items = this.videos.map(el => el.page.properties.module.select.name).sort();
      items.unshift("Todos");

      // Filtrar repetidas
      return new Set(items);
    }
  },
  methods: {
    ...mapMutations(["setSelectedVideo"]),

    videosPerModule(module) {
      let item = this.videos.filter(el => el.page.properties.module.select.name == module);

      return module == "Todos" ? `(${this.videos.length})` : `(${item.length})`;
    },
    async getData() {
      if (this.tutorials) this.videos = this.tutorials;
      else {
        let resp = (await chatbot_api.post('/get_info_tutorials')).data.response;
        this.videos = resp.filter(el => el.page.properties.approved.checkbox);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tutoriales {
  width: 100%;
  height: calc(100vh - 90px);
  grid-template-rows: 90px 1fr;

  .bg-spinner {
    position: relative;
    width: 330px;
    height: 185px;
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    background-color: #e8e8e8;
  }
  .iframe_box {
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #e8e8e8;
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .category {
    width: fit-content;
    list-style: none;
    transition: .25s;
    cursor: pointer;
    
    &:hover, &.active {
      color: #2981EF;
    }
  }
  .item {
    max-width: 330px;
    min-width: 330px;

    &:hover {
      .e-hide {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }
  .p-custom {
    position: absolute;
    top: 0;
    right: 0;
  }
  .mobile-center {
    @media (max-width: 991px) {
      justify-content: center;
    }
  }
  .no_info {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 165px);
    border-left: 1px solid #e8e8e8;

    .no_video {
      width: 100px;
    }
  }
}
.bg_opacity {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .75;
  position: absolute;
  top: 0;
  z-index: 50;
}
</style>