<template>
  <section class="validate-platform" />
</template>

<script>
import dashboard_api from '@/dashboard_api.js';
import { mapMutations, mapState } from 'vuex';

export default {

  data() {
    return {
      platform: '',
      originPlatformBase: {},
    };
  },

  computed: {
    ...mapState(["user", "skip", "platform_name"]),
  },

  watch: {
    user: {
      immediate: true,
      handler(val) {
        this.validatePlatform(val.id);
      }
    },
  },

  methods: {
    ...mapMutations(["toggleSkipGuide", "getPlatformName"]),

    async validatePlatform(user_id) {
      this.originPlatformBase = (await dashboard_api.get(`user_data/${user_id}`)).data.origin_ecommerce;

      let url_shop = this.originPlatformBase.url_origin;
      let show_guide = this.originPlatformBase.welcome_instructions;
      let origins = [{ name: 'shopify' }, { name: 'jumpseller' }];

      if (!this.platform_name) {
        if (url_shop && url_shop.length > 0) {
          this.platform = origins.find(
            el => url_shop.includes(el.name)).name;

            console.log('entre', this.platform);
        } else {
          this.platform = 'databot';
        }
        this.getPlatformName(this.platform);
      }

      if (this.platform_name == 'shopify' && show_guide == true) {
        if (this.skip == false) {
          this.$router.push(`/guide/shop=${url_shop}`);
        } else {
          // solucion conflicto redireccion de validacion bot desactivado
          if (this.$route.name != 'dashboard-planes') {
            if (this.$route.name != 'dashboard-checkout') {
              this.$router.push('/dashboard');
            }
          }
        }
      }
    },
  }
};
</script>

<style land="scss" scoped>
.validate-platform {
  display: none;
}
</style>
