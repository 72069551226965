<template>
  <section class="primer-chatbot">
    <template v-if="completed == false">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <h1 class="mb-5">¡Crea tu primer Chatbot!</h1>
              <DataInput
                label="Nombre del chatbot"
                type="text"
                :model="chatbot.bot_name"
                @newValue="chatbot.bot_name = $event"
                :error="error == 'invalid_name'"
                limit="18"
                required
              />
              <DataInput
                label="Imagen de perfil"
                type="file"
                @newValue="processFile($event)"
              />
              <label
                :class="{
                  'toggle_card w-100 mb-3': true,
                  active: is_ecommerce_input
                }"
                for="skill-base"
              >
                <div class="label-box">
                  <p :class="{'mb-0':true, grey: !is_ecommerce_input}">¿Eres E-commerce?</p>
                  <div class="d-flex justify-content-center align-items-center">
                    <span
                      :class="{
                        'mr-3 mb-0': true,
                        link: is_ecommerce_input,
                        grey: !is_ecommerce_input
                      }"
                    >{{ is_ecommerce_input ? "Sí" : "No" }}</span>
                    <label class="switch">
                      <input type="checkbox" v-model="is_ecommerce_input" id="skill-base" checked>
                      <span class="slider" />
                    </label>
                  </div>
                </div>
              </label>
              <div class="row">
                <div class="p-3 pl-0">
                  <label>Color primario</label>
                  <div class="temas__item-color">
                    <input
                      type="color"
                      v-model="chatbot.bot_title_bar_bg_color"
                      name="bot_title_bar_bg_color"
                    />
                  </div>
                </div>
                <div class="p-3">
                  <label>Color secundario</label>
                  <div class="temas__item-color">
                    <input type="color" v-model="chatbot.bot_title_color" name="bot_title_color" />
                  </div>
                </div>
              </div>
              <button
                :disabled="loading"
                class="btn btn-base second w-100 lg my-3"
                @click.prevent="postChatbot"
              >
                Crear chatbot
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bg_box pb-5">
            <Chatbot
              class="mt-1"
              :chatbot__trama="chatbot.trama_url"
              :chatbot__logo="chatbot.bot_logo_url"
              :chatbot__nombre="chatbot.bot_name"
              :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
              :chatbot__color__secundario="chatbot.bot_title_color"
              :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
              :chatbot__bot__color__letra="chatbot.bot_box_font_color"
              :chatbot__user__color__fondo="chatbot.user_box_bg_color"
              :chatbot__user__color__letra="chatbot.user_box_font_color"
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    </template>
    <!-- <RegisterComplete v-else /> -->
    <br />
  </section>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import dashboard_api from "@/dashboard_api";
import Chatbot from "@/components/Chatbot.vue";
import BulletSteps from "./BulletSteps";
import chatbot_api from "@/chatbot_api.js";
import DataInput from "@/components/builders/DataInput";
import axios from "axios";
import { reverseString } from '@/utils/utils';
import businessTypes from '@/services/api/dt_client_business_types.js';
// import RegisterComplete from '../integrations/RegisterComplete';

export default {
  name: "firstbot",
  props: ["step", "web"],
  components: {
    Chatbot,
    BulletSteps,
    DataInput,
    // RegisterComplete
  },
  data() {
    return {
      loading: false,
      completed: false,
      is_ecommerce_input: true,
      chatbot: {
        bot_logo_url: "",
        bot_name: "Chatbot",
        user_box_bg_color: "#ffffff",
        user_box_font_color: "#525252",
        bot_box_bg_color: "#D8EEFF",
        bot_box_font_color: "#525252",
        bot_title_bar_bg_color: "#181818",
        bot_title_color: "#ffffff",
        trama_url: "",
        web: this.web,
        business_types_id: 0,
        time_zone: "",
        industries: "",
        has_skill_base: true,
        skill_base_template: "generica",
        init_conver_mobile: false,
        is_databot_live_3: true
      },
      error: {
        name: false,
        chatbot: ""
      },
      image: '',
      business_types: [
        {
          label: "Tienda online",
          value: "Ecommerce",
        },
        {
          label: "Otro",
          value: "Otro",
        },
      ],
      my_register: {}
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.chatbot.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getBusinessType();
  },
  computed: {
    ...mapState([
      "user",
      "registerData",
      "active_view",
      "platform_name",
      "platform_shop",
      "platform_access"
    ]),
    getSelectedTemplate() {
      if (this.is_ecommerce_input) this.chatbot.skill_base_template = 'ecommerce';

      switch (this.chatbot.skill_base_template) {
        case 'ecommerce':
          return 'TEST001'
        default:
          return 'generica';
      };
    }
  },
  methods: {
    ...mapMutations(["setRegisterProcess", "setActiveView"]),

    scrapping(bot_id) {
      if (!bot_id) return console.log("scrapping no id->");
      else if (this.web == '') return console.log("scrapping no web->");
      else {
        let data = JSON.stringify({
          url: this.web,
          bot_id
        });
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://rasaia2.databot.cl/api/assistants/load_knowledge_webscrawler`,
          headers: {
            'Content-Type': 'application/json'
          },
          data
        };
        axios.request(config)
        .then((response) => {
          console.log('scrapping here->', JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      };
    },
    async getBusinessType() {
      // this.chatbot.business_types_id = (await dashboard_api.get(`/business_types/by_name/${this.selected_business}`)).data.id;
      this.chatbot.business_types_id = (await dashboard_api.get(`/business_types/by_name/Ecommerce`)).data.id;
    },
    processFile(event) {
      const self = this;
      const reader = new FileReader();
      console.log("event first bot->", event);

      self.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        self.chatbot.bot_logo_url = reader.result;
      };
      reader.onerror = function (error) {};
    },

    formDataBody() {
      const data = new FormData();

      const {
        bot_name,
        bot_title_bar_bg_color,
        business_types_id,
        bot_title_color,
        has_skill_base,
        skill_base_template,
        time_zone,
        web,
        init_conver_mobile,
        is_databot_live_3
      } = this.chatbot;

      console.log("this.web->", this.web);

      data.append("chatbot[bot_name]", bot_name);
      data.append("chatbot[user_box_bg_color]", "#ffffff");
      data.append("chatbot[user_box_font_color]", "#525252");
      data.append("chatbot[bot_box_bg_color]", "#D8EEFF");
      data.append("chatbot[bot_box_font_color]", "#525252");
      data.append("chatbot[bot_title_bar_bg_color]", bot_title_bar_bg_color);
      data.append("chatbot[bot_title_color]", bot_title_color);
      data.append("chatbot[business_types_id]", business_types_id);
      data.append("chatbot[trama_url]", "");
      data.append("chatbot[has_skill_base]", has_skill_base);
      data.append("chatbot[skill_base_template]", this.is_ecommerce_input ? 'ecommerce' : 'generica');
      data.append("chatbot[web]", web);
      data.append("activated", true);
      data.append("chatbot[time_zone]", time_zone);
      data.append("chatbot[init_conver_mobile]", init_conver_mobile);
      data.append("chatbot[is_databot_live_3]", is_databot_live_3);
      if (this.image) data.append("chatbot[bot_avatar]", this.image);

      return data;
    },

    postChatbot() {
      const self = this;
      self.error.name = !self.chatbot.bot_name;
      self.my_register = self.registerData;
      let bots = [];

      console.log("registerData->", self.registerData);
      console.log("Nuevo chatbot->", self.chatbot);

      if (!self.error.name && !self.error.chatbot) {
        self.loading = true;

        if (self.chatbot.bot_logo_url == "/img/brand/0-default.svg") self.chatbot.bot_logo_url = null;

        self.$swal({
          icon: "info",
          title: "Creando chatbot...",
          showConfirmButton: false
        });
        self.$swal.showLoading();

        // if (self.platform_name == "shopify") {
        //   self.chatbot.web = self.$route.query.shop_domain;
        // } else {
        //   self.chatbot.web = self.user.url;
        // }
        const formData = self.formDataBody();

        dashboard_api
          .post("/bot", formData)
          .then(async bot => {
            // For skill base template for all bots
            let bot_id = bot.data.id;
            let final = {};
            let template = self.getSelectedTemplate;

            self.loading = false;
            self.my_register.bot = bot.data.id;
            self.my_register.botName = bot.data.bot_name;
            self.my_register.botToken = bot.data.token;
            self.my_register.customerId = self.registerData.customerId;

            bots.unshift(self.chatbot);
            self.$store.commit("setOwnerBots", bots);
            // comento segun lo conversado con don mati 15/10/24
            // self.scrapping(bot_id);

            console.log("bot--->", bot);

            try {
              await Promise.all([
                self.$store.dispatch('dtClientIntentsModule/intentComplete', {
                  template,
                  bot_id,
                }),
                self.$store.dispatch('dtClientNodesModule/nodeComplete', {
                  template,
                  bot_id,
                }),
                self.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
                  template,
                  bot_id,
                }),
              ]);
              await self.$store.dispatch('dtClientSkillsModule/skillCreate', {
                bot_id,
                client_name: `${bot_id}-PROD`,
                template,
              });
              let uploadSkill = await self.$store.dispatch(
                'dtClientSkillsModule/skillUploadLLM',
                {
                  bot_id,
                },
              );
              let assistantId = uploadSkill.payload.assistant_id;
              console.log("assistantId->", assistantId);
              let inverseAssistantId = reverseString(assistantId);
              let allBusinessTypes = await self.$store.dispatch(
                'dtClientBusinessTypeModule/businessTypesListAll',
              );
              allBusinessTypes = allBusinessTypes.map(bot => bot.assistant_id);
              if (!allBusinessTypes.includes(inverseAssistantId)) {
                let createBusinessTypeId = await self.$store.dispatch(
                  'dtClientBusinessTypeModule/businessTypeCreate',
                  {
                    name: `${bot_id}-PROD`,
                    show: false,
                    product_type: 'chatbot',
                    has_persistence: true,
                    icon: '',
                    activated: true,
                    custom: true,
                    api_key: '',
                    assistant_id: assistantId,
                    skill_id: '',
                  },
                );
                let newBusinessTypeId = createBusinessTypeId.id;
                console.log('createdbusinessTypeId: ', newBusinessTypeId);
                final = await businessTypes.updateBusinessType(bot_id, {
                  chatbot: {
                    id: bot_id,
                    business_types_id: newBusinessTypeId,
                  },
                });
              }
              console.log("final->", final);

              if (final.status == 201) {
                self.$swal({
                  icon: "info",
                  title: "Terminando configuración...",
                  showConfirmButton: false
                });
                self.$swal.showLoading();
                // Acá va lo nuevo
                let plan = "plan profesional";

                dashboard_api
                  .get(`/base_plans/with_channel_by_name/${plan}`)
                  .then(withChannelByNameResponse => {
                    let data = withChannelByNameResponse.data;

                    dashboard_api
                      .post("/admissible_plans", {
                        base_plan_id: data.plan.id,
                        channels: [data.channel.id],
                        extra_licences: 0,
                        price: data.plan.price,
                        period: "monthly"
                      })
                      .then(admissiblePlansResponse => {
                        // let daysLeft = self.platform_name == 'shopify' ? 14 : 7;
                        let daysLeft = 7;

                        dashboard_api
                          .post("/client_subscriptions", {
                            bot_id: bot.data.id,
                            user_id: self.user.id,
                            admissible_plan_id: admissiblePlansResponse.data.id,
                            status: true,
                            days_left: daysLeft,
                            test_period: true
                          })
                          .then(async () => {
                            self.ultimoPaso();
                            // Crea rol de usuario
                            dashboard_api
                              .post('/userbot/createuserbot', {
                                user_bot: {
                                  user_id: self.user.id,
                                  bot_id: bot.data.id,
                                  // role_id,
                                  category: ["ventas", "facturación", "post-venta", "soporte", "general"]
                                }
                              })
                              .then(resp => {
                                console.log("resp userbot->", resp);

                                chatbot_api
                                  .post("/implementar", {
                                    id: bot.data.id,
                                    token: bot.data.token,
                                    token_access: self.$route.query.token_access
                                  })
                                  .then(response => {
                                    console.log("chatbot implementado->", response);
                                    console.log("token_access->", self.$route.query.token_access);

                                    self.$swal({
                                      icon: "success",
                                      title: "¡Chatbot creado!",
                                      timer: 1000,
                                      showConfirmButton: false
                                    });
                                    // flujo cuando usuario se registra desde CMS's
                                    if (self.platform_name && self.platform_name != 'databot') {
                                      console.log("entro a platform name->");
                                      self.my_register.step = self.is_ecommerce_input ? 3 : 5;
                                    } else {
                                      self.my_register.step = self.is_ecommerce_input ? 3 : 4;
                                      self.$router.push({
                                        query: {
                                          name: "implement",
                                          shop_domain: self.platform_shop,
                                          token_access: self.platform_access
                                        }
                                      });
                                    };
                                    self.setRegisterProcess(self.my_register);
                                  })
                                  .catch(error => console.log(error));
                              })
                              .catch(err => {});
                          })
                          .catch(error => console.error("error ->", error));
                      })
                      .catch(error => console.error("error ->", error));
                  })
                  .catch(error => console.error("error ->", error));
              }
            } catch (error) {
              console.log("error->", error);
            }
          })
          .catch(error => {
            self.loading = false;
            console.log("error->", error);
          });
      }
    },
    checkRequiredData() {
      if (!this.name) {
        this.error.name = true;
        return false;
      }
      return true;
    },
    // Esto es lo que estaba en BusinessType.vue
    // TODO mejorar
    ultimoPaso() {
      try {
        const self = this;
        dashboard_api
          .post("/mails/landing", {
            name: self.user.name,
            email: self.user.email,
            phone: self.user.phone,
            mensaje: self.user.url
          })
          .then(responseemail => {
            // chatbot_api
            //   .get("/getuser", {
            //     params: {
            //       email: self.user.email
            //     }
            //   })
            //   .then(async getdatauser => {
            //     if (getdatauser.data.error) {
            //       chatbot_api
            //         .post("/sendlead", {
            //           email: self.user.email,
            //           name: self.user.name,
            //           web: self.user.url,
            //           phone: self.user.phone
            //         })
            //         .then(async response => {
            //           // realiza la busqueda de la empresa
            //           const getcompany = await self.getcompany();

            //           if (getcompany.data.response.results != "") {
            //             // Agrega el lead a la empresa
            //             await self.addleadcompany(response, getcompany);

            //             await self.newbusiness(getcompany, response);
            //           } else {
            //             // crea la empresa
            //             await self.createcompany();

            //             // realiza la busqueda de la empresa
            //             const getcompanycreate = await self.getcompany();

            //             if (getcompanycreate.data.response.results.length == 1) {
            //               await self.addleadcompany(response, getcompanycreate);

            //               await self.newbusiness(getcompanycreate, response);
            //             } else {
            //               const companyresults = getcompanycreate.data.response.results;

            //               companyresults.forEach(async element => {
            //                 if (element.properties.name) {
            //                   if (element.properties.name.value == self.user.url) {
            //                     // eliminar compañia
            //                     await self.deletebusiness(element);

            //                     const getcompanycreate2 = await self.getcompany();

            //                     await self.newbusiness(getcompanycreate2, response);
            //                   }
            //                 } else {
            //                 }
            //                 // end foreach
            //               });
            //             }
            //           }
            //         })
            //         .catch(error => console.log("errorrrrrrrrr =>", error));
            //     } else {
            //       const getcompany = await self.getcompany();
            //       if (getcompany.data.response.results != "") {
            //         // realiza el agregar
            //         await self.addleadcompany(getdatauser, getcompany);

            //         await self.newbusiness(getcompany, getdatauser);
            //       } else {
            //         // crea la empresa
            //         await self.createcompany();

            //         const getcompanycreate = await self.getcompany();

            //         if (getcompanycreate.data.response.results.length == 1) {
            //           // realiza el agregar
            //           await self.addleadcompany(getdatauser, getcompanycreate);
            //           // crea el negocio
            //           await self.newbusiness(getcompanycreate, getdatauser);
            //         } else {
            //           const companyresults = getcompanycreate.data.response.results;
            //           companyresults.forEach(async element => {
            //             if (element.properties.name) {
            //               if (element.properties.name.value == self.user.url) {
            //                 await self.deletebusiness(element);

            //                 // realiza la busqueda de la empresa
            //                 const getcompanycreate2 = await self.getcompany();

            //                 // crea el negocio
            //                 await self.newbusiness(getcompanycreate2, getdatauser);
            //               }
            //             } else {
            //             }
            //           });
            //         }
            //       }
            //     }
            //   });
          })
          .catch(error => console.log("error =>", error));
      } catch (error) {
        console.log(error);
      }
    },

    createcompany() {
      const self = this;
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/createcompany", {
            name: self.user.url
          })
          .then(async company => {
            resolve(company);
          })
          .catch(error => reject(error));
      });
    },

    getcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .get("/getcompany", {
            params: {
              domain: this.user.url
            }
          })
          .then(getcompany => {
            resolve(getcompany);
          })
          .catch(error => reject(error));
      });
    },

    deletebusiness(element) {
      chatbot_api
        .delete("/deletebusiness", {
          params: {
            id: element.companyId
          }
        })
        .then(async responsse => {});
    },

    addleadcompany(response, getcompany) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .put("/addleadcompany", {
            client: response.data.response.vid,
            company: getcompany.data.response.results[0].companyId
          })
          .then(addcompany => {
            resolve(addcompany);
          })
          .catch(error => reject(error));
      });
    },
    newbusiness(getcompany, response) {
      const self = this;
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/newbusiness", {
            companiid: getcompany.data.response.results[0].companyId,
            clientid: response.data.response.vid,
            domain: self.user.url,
            from: self.$route.name
          })
          .then(business => {
            // undefined dealID
            if (business.data && business.data.response && business.data.response.dealId) {
              dashboard_api
                .put(`/bot/${self.registerData.bot}`, {
                  chatbot: {
                    id: self.registerData.bot,
                    hubspot_deal_id: business.data.response.dealId
                  }
                })
                .then(response => {})
                .catch(error => console.log("error ->", error));
            }
          })
          .catch(error => reject(error));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.primer-chatbot {
  width: 100%;

  .btn-base {
    font-size: 1rem;
  }
  .bg_box {
    background: linear-gradient(180deg, #ffffff 0%, #DEEDFF 100%);
    border-radius: 1.5rem;
  }
  .temas__item-color {
    display: flex;
    margin-top: 1rem;

    input {
      height: 53px;
      width: 50px;
      padding: 0;
      border: none;
      box-shadow: none;
      border-radius: none;
      background: transparent;
      cursor: pointer;

      &[type="color"]::-moz-color-swatch {
        box-shadow: none;
        border-radius: 5px;
      }
      &[type="color"]::-webkit-color-swatch-wrapper {
        border-radius: 5px;
        border-style: none;
      }
      // edita la forma del input color
      &[type="color"]::-webkit-color-swatch {
        box-shadow: none;
        border-radius: 5rem;
        border: 1px solid #cdcdcd;
      }
    }
  }
  .toggle_card {
    height: 55px;
    display: flex;
    align-items: center;

    p, span {
      font-size: 1rem;
    }
  }
}
</style>
