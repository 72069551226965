<template>
  <section class="dashboard-checkout">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <UsdDashboardCheckout v-if="platform_name == 'shopify'" />
        <UfDashboardCheckout v-if="platform_name != 'shopify'" />
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import UfDashboardCheckout from './Components/UfDashboardCheckout';
import UsdDashboardCheckout from './Components/UsdDashboardCheckout';
import { FadeTransition } from "vue2-transitions";

export default {
  name: 'dashboard-checkout',

  components: {
    UfDashboardCheckout,
    UsdDashboardCheckout,
    FadeTransition
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      showLogin: false,
      showRegister: false,
      loading: true,
    };
  },

  computed: {
    ...mapState(["platform_name", "view_permissions"])
  }
};
</script>
