<template>
  <section class="checkout mb-5" id="top">
    <ValidatePlatform v-if="user" :user="user" />
    <div class="row justify-content-center">
      <div class="col-lg-4 mb-5">
        <h1>
          {{ checkoutData.product.name }}
          <span class="price"
            >{{ checkoutData.product.uf_price }} UF</span
          >
        </h1>
        <!-- <p v-if="priceOffer" class="before-price">Precio antes: {{ priceToShow }} UF.</p> -->
        <p>
          Costo
          {{ checkoutData.plan_period == "monthly" ? "mensual" : "anual" }}.
        </p>
        <hr class="my-4" />
        <div class="hide-info" :class="{ 'show-info': show_info == true }">
          <div class="trama" />
          <label class="mb-4"> Tu plan incluye: </label>
          <p class="info">
            <i class="fas fa-check" />Licencias:
            <span class="destacado">{{
              parseInt(checkoutData.product.licences) | clp
            }}</span
            >.
          </p>
          <p class="info">
            <i class="fas fa-check" />Nº de conversaciones:
            <span class="destacado"
              >{{
                parseInt(checkoutData.product.conversations) | clp
              }}.</span
            >
          </p>
          <p
            class="info"
            v-for="(info, index) in checkoutData.product.info"
            :key="index"
          >
            <i class="fas fa-check" />
            {{ info }}
          </p>
        </div>
        <p v-if="!show_info" @click="show_info = true" class="btn-text my-4">
          Ver todas
        </p>
      </div>
      <div class="col-lg-4 mb-5">
        <div v-if="checkoutData.product.name != 'starter'">
          <div v-for="channel in channels" :key="channel.name" class="card mt-0">
            <div class="row justify-content-between align-items-center">
              <label for="channel.name"
                >Incluir {{ channel.name }}</label
              >
              <label>{{ channel.uf_price }} UF</label>
            </div>
            <div class="d-flex justify-content-between">
              <p class="status mb-0">
                <i v-if="pickedChannels != 0" class="fas fa-check" />
                {{ pickedChannels != 0 ? "Chatbot con WhatsApp" : "Chatbot sin WhatsApp" }}
              </p>
              <div class="buefy">
                <b-switch v-model="pickedChannels" :id="channel.id" :native-value="channel.id" />
              </div>
            </div>
            <p v-if="pickedChannels != 0" class="text_sm grey mt-3 mb-0">
              Cuentas con 1000 mensajes de plantilla gratis para envíos masivos. Después de esa
              cantidad se aplican los <a><b>siguientes costos</b></a>.
            </p>
          </div>
        </div>
        <div v-if="checkoutData.product.name != 'starter'" class="card">
          <div class="row justify-content-between align-items-center">
            <label for="agents">Licencias de agentes</label>
            <label>0.5 UF</label>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="status mb-0 mr-2">
              <i v-if="agents !== 0" class="fas fa-check"> </i
              >{{
                agents ? `Licencias extra: ${agents}` : "Sin licencias extra"
              }}
            </p>
            <div class="number-input">
              <button class="btn-left" @click="lessAgent" />
              <input
                type="number"
                name="agents"
                id="agents"
                v-model="agents"
                min="0"
                maxlength="2"
                placeholder="0"
              />
              <button class="btn-right" @click="addAgent" />
            </div>
          </div>
        </div>
        <!-- <div class="info_box">
          <p>¡Responde automáticamente y genera leads también desde tu <span class="destacado">WhatsApp</span>! Puedes agregar tu chatbot con WhatsApp en las opciones de abajo.
          </p>
        </div> -->
        <div class="card">
          <div class="d-flex mb-3 justify-content-between aling-items-center">
            <label class="mb-0">Detalle de compra</label>
            <div v-if="userInfo && !userInfo.credit_card_type" />
            <div v-else>
              <img
                v-if="userInfo.credit_card_type == 'Visa'"
                src="/img/icons/11-visa.svg"
                alt=""
              />
              <img
                v-else
                class="mr-2"
                src="/img/icons/11-mastercard.svg"
                alt=""
              />
            </div>
          </div>
          <div class="info_box">
            <div class="row justify-content-between">
              <p>{{ checkoutData.product.name }}</p>
              <p>
                {{ checkoutData.product.uf_price }} UF
              </p>
            </div>
            <div v-if="priceChannels !== 0" class="row justify-content-between">
              <p>Chatbot WhatsApp</p>
              <p>{{ priceChannels }} {{ platform_name == "shopify" ? "USD" : "UF" }}</p>
            </div>
            <div v-if="priceAgents !== 0" class="row justify-content-between">
              <p>Licencias de agentes</p>
              <p>{{ priceAgents }} {{ platform_name == "shopify" ? "USD" : "UF" }}</p>
            </div>
          </div>
          <div class="d-flex mt-3 mb-4 justify-content-between">
            <h3 class="mb-0">Total</h3>
            <h3 class="mb-0">{{ priceToShow }} UF</h3>
          </div>
          <div class="d-flex justify-content-center">
            <button
              v-if="userInfo && !userInfo.credit_card_type"
              @click="AddCreditCard()"
              class="btn btn-base second lg"
            >
              <i class="fas fa-lock" />Agregar tarjeta
            </button>
            <button v-else @click="preSubscription()" class="btn btn-base second lg">
              <i class="fas fa-lock" />Suscribirme
            </button>
          </div>
        </div>
        <p class="text-sm text-center">
          Tu servicio se renovará con cargo a tu tarjeta al final de cada mes.
        </p>
      </div>
    </div>
    <br />
    <div class="col-lg-8 justify-content-center m-auto">
      <div class="info_box mb-5">
        <label class="mb-0"><i class="fas fa-info-circle" /> Importante </label>
        <p class="mb-0">
          Al suscribirme autorizo a Databot realizar el cargo a mi tarjeta por
          los periodos relacionados al plan que he seleccionado. Conoce la
          referencia de costos de WhatsApps API
          <span @click="showWhatsAppTable" class="destacado click">aquí</span>.
        </p>
      </div>
      <WhatsappTable :show="whatsappTable" />
      <!-- TODO Sistema de planes con flow deprecado -->
      <!-- <router-link
        :to="{
          name: 'dashboard-planes',
          query: { seccion: 'planes' }
        }"
        class="btn-text"
      >
        &lt;- Volver a planes
      </router-link> -->
    </div>
  </section>
</template>

<script>
import ValidatePlatform from "@/components/ValidatePlatform";
import WhatsappTable from "./WhatsappTable";
import { mapState, mapMutations } from "vuex";
import dashboard_api from "@/dashboard_api";
import payment_api from "@/payment_api";
import "@/assets/buefy_class.scss";

export default {
  name: "checkout",
  components: {
    WhatsappTable,
    ValidatePlatform
  },
  data() {
    return {
      show_info: false,
      whatsappTable: false,
      channels: [],
      payment_type: null,
      selectedValue: 1,
      profile: {},
      kushki_merchant_id: "",
      test: true,
      currentDate: new Date().getDate(),
      nextYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      plan: {},
      queries: this.$route.query,
      whatsapp: false,
      pickedChannels: [],
      agents: 0,
      priceToShow: 0,
      priceChannels: 0,
      priceAgents: 0,
      creditCard: "",
      paidOut: false,
      userInfo: {},
      bots: {},
      extras: []
    };
  },

  created() {
    window.scrollTo(0, 0);
    this.getChannels();
    this.getLicences();
    // this.haveARegisterCreditCard();
    this.getUserInfo();

    if (origin == "https://dashboard-front-qa.herokuapp.com") {
      this.kushki_url = "https://qapi.databot.cl/qvo_plans/confirmation";
      this.webpay_url =
        "https://qapi.databot.cl/qvo_plans/async_card_subscription";
      this.status_url = "https://qapi.databot.cl/qvo_plans/webpay_status";
      this.kushki_merchant_id = "a5c21654546147d198a1afe234edeaed";
      this.test = true;
    } else if (process.env.NODE_ENV === "development") {
      this.kushki_url = "http://localhost:3000/qvo_plans/confirmation";
      this.webpay_url =
        "http://localhost:3000/qvo_plans/async_card_subscription";
      this.status_url = "http://localhost:3000/qvo_plans/webpay_status";
      this.kushki_merchant_id = "a5c21654546147d198a1afe234edeaed";
      this.test = true;
    } else {
      this.kushki_url = "https://api.databot.cl/qvo_plans/confirmation";
      this.webpay_url =
        "https://api.databot.cl/qvo_plans/async_card_subscription";
      this.status_url = "https://api.databot.cl/qvo_plans/webpay_status";
      this.kushki_merchant_id = "d6f2284b877445e3aca68406ae92dacf";
      this.test = false;
    }
    if (this.queries && this.queries.status == "failure") {
      this.$swal({
        icon: "error",
        title: "Ha ocurrido un problema con el pago",
        text: "Por favor reintente",
      });
    }
    if (this.queries && this.queries.status == "failure_transaction") {
      this.$swal({
        icon: "error",
        title: "Ha ocurrido un problema con el pago",
        text: "Tarjeta rechazada",
      });
    }

    if (this.queries && this.queries.status == "successCreditCard") {
      this.$swal({
        icon: "success",
        title: "Tarjeta agregada con éxito",
      });
    }

    if (this.queries && this.queries.status == "successSubscription") {
      this.$swal({
        icon: "success",
        title: "Suscripción creada",
      });
      this.$router.push({ path: `/pago-exitoso/${this.checkoutData.bot_id}` });
    }

    console.log('this.checkoutData >', this.checkoutData);

    this.priceToShow = this.checkoutData.product.uf_price;
  },

  computed: {
    ...mapState(["user", "checkoutData", "selected_bot", "platform_name"]),

    getRemanningDays() {
      let date = new Date();
      let time = new Date(date.getTime());
      time.setMonth(date.getMonth() + 1);
      time.setDate(0);
      let days =
        time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;

      return days;
    },
  },

  methods: {
    ...mapMutations(["setRegisterProcess", "cleanRegisterData"]),

    getUserInfo() {
      let self = this;
      dashboard_api
        .get(`/qvo_plans/by_bot_id/${self.checkoutData.bot_id}`)
        .then((qvoResponse) => {
          if (qvoResponse.data.length == 0) {
            dashboard_api
              .get(`/client_subscriptions/by_bot_id/${self.checkoutData.bot_id}`)
              .then(clientResponse => {
                // console.log('clientResponse >', clientResponse.data);
                if (clientResponse.data && clientResponse.data.paid_out == true) {
                  self.paidOut = true;
                }
                // console.log('paidOut >', self.paidOut);
              })
              .catch(error => console.error("error >", error));
          } else if (qvoResponse.data.paid_out == true) {
            self.paidOut = true;
          }
        })
        .catch((error) => console.error("error >", error));

      dashboard_api
        .get(`/client_facturacion_data/by_user_id/${self.user.id}`)
        .then((response) => {
          if (response.data.costumer_id) {
            self.userInfo = response.data;
          } else {
            dashboard_api
              .post("/flow_requests/create_customer", {
                flow_request: {
                  name: self.user.name,
                  email: self.user.email,
                  externalId: self.user.id,
                },
              })
              .then((flowResponse) => self.userInfo = flowResponse.data)
              .catch((error) => console.log(error)); // reject(error));
          }
        })
        .catch((error) => console.error(error));
    },

    AddCreditCard() {
      dashboard_api
        .post("/flow_requests/add_credit_card", {
          customer_id: this.userInfo.costumer_id,
        })
        .then((addCreditCardResponse) => {
          location.replace(addCreditCardResponse.data.redirect_to);
        })
        .catch((error) => console.error("error ->", error));
    },

    haveARegisterCreditCard() {
      dashboard_api
        .get(
          `/client_facturacion_data/by_costumer_id/${this.userInfo.costumer_id}`
        )
        .then((byCostumerIdResponse) => {
          this.creditCard = byCostumerIdResponse.data.credit_card_type
            ? byCostumerIdResponse.data.credit_card_type
            : "";
        })
        .catch((error) => console.error("error ->", error));
    },

    showWhatsAppTable() {
      this.whatsappTable = !this.whatsappTable;
    },

    addAgent() {
      if (this.agents < this.maxAgents()) return this.agents++;
    },

    lessAgent() {
      if (this.agents >= 1) return this.agents--;
    },

    async getChannels() {
      // this.channels = (await dashboard_api.get("/channels/no_web_plans")).data;
      this.channels = (await payment_api.get("/channels")).data;
    },

    async getLicences() {
      // this.channels = (await dashboard_api.get("/channels/no_web_plans")).data;
      this.extras = (await payment_api.get("/extra_items")).data;
    },

    preSubscription() {
      // let self = this;
      // let totalAPagar = 0;

      // // if (this.getRemanningDays != 0 && self.$route.query.seccion == "new" && !self.paidOut) {
      // if (self.getRemanningDays != 0 || (self.$route.query.seccion == "new" && !self.paidOut)) {
      //   totalAPagar = ((self.priceToShow / 30) * self.getRemanningDays).toFixed(1);

      //   self.$swal({
      //     title: "Confirmación de suscripción",
      //     text: `Se realizará un cargo a su tarjeta de ${totalAPagar} ${self.platform_name == 'shopify' ? 'USD' : 'UF'} correspondiente al proporcional de los los días hasta el último día del mes.`,
      //     icon: "info",
      //     confirmButtonText: "Listo",
      //     showCancelButton: true,
      //     cancelButtonText: "Cancelar"
      //   })
      //   .then(result => {
      //     if (result.isConfirmed) {
      //       self.proportionalPayment(totalAPagar);
      //     }
      //   });
      // } else {

      let id = this.checkoutData.channel_data.id;
      if (!this.pickedChannels.includes(id)) this.pickedChannels.push(id);

      dashboard_api
        .post("/admissible_plans", {
          admissible_plan: {
            base_plan_id: this.checkoutData.plan_data.id,
            channels: this.pickedChannels,
            extra_licences: this.agents,
            price: this.checkoutData.plan_data.price,
            period: this.checkoutData.plan_period,
          },
        })
        .then((admissible_plans_response) => {
          if (this.checkoutData.plan_data.id) {
            if (
              admissible_plans_response.data.name ==
              this.checkoutData.plan_data.name
            ) {
              this.$swal({
                icon: "info",
                title: "Sin cambios",
                text: "La configuración del plan es igual al existente",
              });
            } else {
              this.$swal({
                icon: "info",
                title: "Importante!",
                text: "Tu nuevo plan quedará vigente desde este momento",
                confirmButtonText: "Aceptar",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.createSubscription(admissible_plans_response.data);
                }
              });
            }
          } else {
            this.createSubscription(admissible_plans_response.data);
          }
        })
        .catch((error) => console.log("error ->", error));
      // }
    },

    createSubscription(admissible_plan) {
      let id = this.checkoutData.channel_data.id;
      if (!this.pickedChannels.includes(id)) this.pickedChannels.push(id);

      if (this.platform_name == "shopify") {
        // Shopify
        this.shopifyPayment();
      } else {
        this.$swal({
          icon: "info",
          title: "Estamos suscribiendo su plan...",
          showConfirmButton: false,
        });
        this.$swal.showLoading();
        // Flow
        dashboard_api
          .post("/flow_requests/create_plan", admissible_plan)
          .then((create_plan_response) => {
            // Eliminar suscripción actual
            if (this.checkoutData.subscription.subscription_id != "") {
              dashboard_api
                .post("/flow_requests/cancel_subscription", {
                  id: this.checkoutData.subscription.id,
                })
                .then((cancelResponse) => {
                  // Crear nueva suscripción
                  dashboard_api
                    .post("/flow_requests/create_subscription", {
                      plan_id: create_plan_response.data.plan_id,
                      admissible_plan_id: admissible_plan.id,
                      customer_id: this.userInfo.costumer_id,
                      bot_id: this.checkoutData.bot_id,
                    })
                    .then(
                      this.$swal({
                        icon: "success",
                        title: "Suscripción creada",
                        timer: 800,
                        showConfirmButton: false,
                      }).then(() => {
                        this.$router.push(
                          `/pago-exitoso/${this.checkoutData.bot_id}`
                        );
                      })
                    )
                    .catch((error) => console.error("error ->", error));
                })
                .catch((error) => console.error("error ->", error));
            } else {
              // Crear nueva suscripción
              dashboard_api
                .post("/flow_requests/create_subscription", {
                  plan_id: create_plan_response.data.plan_id,
                  admissible_plan_id: admissible_plan.id,
                  customer_id: this.userInfo.costumer_id,
                  bot_id: this.checkoutData.bot_id,
                })
                .then(
                  this.$swal({
                    icon: "success",
                    title: "Suscripción creada",
                    timer: 800,
                    showConfirmButton: false,
                  }).then(() => {
                    // this.$router.push(
                    //   `/pago-exitoso/${this.checkoutData.bot_id}`
                    // );
                  })
                )
                .catch((error) => console.error("error ->", error));
            }
          })
          .catch((error) => console.error("error -> ", error));
      }
    },

    // Realiza un cargo inmediato a la tarjeta de crédito agregada
    proportionalPayment(amount) {
      let self = this;

      self.$swal({
        icon: "info",
        title: "Estamos suscribiendo su bot...",
        showConfirmButton: false,
      });
      self.$swal.showLoading();

      dashboard_api
        .post("/flow_requests/make_charge", {
          customer_id: self.userInfo.costumer_id,
          amount,
          subject: `pago proporcional bot ${self.checkoutData.bot_id}`,
          commerce_order: `${self.checkoutData.bot_id}-${self.checkoutData.plan_data.name}-${self.userInfo.costumer_id}`,
          optionals: { client_id: self.user.id },
        })
        .then((makeChargeResponse) => {
          if (Object.keys(makeChargeResponse.data).includes("code")) {
            if (makeChargeResponse.data.code == "1605") {
              self.createSubscription();
            } else {
              self.$swal({
                icon: "error",
                title: "Ocurrió un problema al realizar el pago",
                timer: 800,
                showConfirmButton: false,
              });
            }
          } else {
            switch (makeChargeResponse.data.status) {
              case 1:
                self.$swal({
                  icon: "error",
                  title: "Ocurrió un problema al procesar el pago",
                  timer: 800,
                  showConfirmButton: false,
                });
                break;
              case 2:
                self.createSubscription();
                break;
              case 3:
                self.$swal({
                  icon: "error",
                  title: "El pago fué rechazado por el porveedor del servicio",
                  timer: 800,
                  showConfirmButton: false,
                });
                break;
              case 4:
                self.$swal({
                  icon: "error",
                  title: "El pago fué anulado por el cliente",
                  timer: 800,
                  showConfirmButton: false,
                });
                break;
            }
          }
        })
        .catch((error) => console.error("error ->", error));
    },

    sumValues() {
      console.log('this.checkoutData.product.uf_price >', this.checkoutData.product.uf_price);
      console.log('this.priceChannels >', this.priceChannels);
      console.log('priceAgents >', this.priceAgents);
      return (
        this.checkoutData.product.uf_price +
        this.priceChannels +
        this.priceAgents
      );
    },

    maxAgents() {
      switch (this.checkoutData.plan_data.name) {
        case "Plan Basic":
          return 2;
        case "Plan Starter":
          return 7;
        case "Plan Profesional":
          return 10;
      }
    },

    shopifyPayment() {
      // a shopify
      dashboard_api
        .post("/subscribe", {
          bot_id: this.checkoutData.bot_id,
          plan: this.checkoutData.plan_data,
          plan_name: this.checkoutData.plan_data.name,
          plan_price: this.checkoutData.plan_data.price,
          test: true,
        })
        .then((response) => {
          if (response.data.userErrors.length == 0) {
            // location.replace(response.data.confirmationUrl)
            window.open(response.data.confirmationUrl, "_blank");
          } else {
            this.$swal({
              icon: "error",
              title: "Ha ocurrido un problema",
              text: response.data.userErrors.map(error => error.message).join(", "),
              showConfirmButton: false
            });
          }
        })
        .catch();
    },
  },

  watch: {
    // Maneja el comportamiento al agregar o quitar un canal
    pickedChannels(values) {
      let aux = 0;
      let channel;

      console.log('values >', values);

      console.log('channels >', this.channels);

      values.forEach(element => {
        console.log('element >', element);
        channel = this.channels.filter(item => item.id == element);
        console.log('channel >', channel);
        console.log('channel.length >', channel.length);
        if (channel.length) aux += channel[0].uf_price;
      });

      this.priceChannels = aux;
      this.priceToShow = this.sumValues();
    },

    // Maneja el comportamiento al agregar o quitar agentes
    agents(value) {
      this.priceAgents = this.checkoutData.product.uf_price * value;
      this.priceToShow = this.sumValues();
    },
  },
};
</script>

<style lang="scss">
.checkout {
  .buefy .switch {
    right: 0;
  }
}
</style>

<style lang="scss" scoped>
.checkout {
  padding: 5rem 0.5rem;

  .price {
    margin-bottom: 0;
    color: #2981ef;
  }
  select {
    padding: 1rem;
    margin: 2rem auto;
  }
  .fa-check {
    font-size: 80%;
    color: #2981ef;
    margin-right: 0.5rem;
  }
  hr {
    text-align: left;
    width: 20%;
    border-top: 1px solid #e8e8e8;
    margin: 0;
  }
  .fa-info-circle {
    font-size: 1rem;
    color: #2981ef;
  }
  .card {
    margin: 1.5rem 0;
    padding: 1.5rem;

    .info_box {
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  .hide-info {
    position: relative;
    height: 400px;
    overflow-y: hidden;
    z-index: 0;

    .trama {
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(transparent, #fafafa);
      z-index: 5;
    }
  }
  .show-info {
    animation-name: second-fade-y-down;
    animation-duration: 0.5s;
    height: auto;

    .trama {
      background: transparent;
    }
  }

  @keyframes second-fade-y-down {
    from {
      height: 400px;
    }
    to {
      height: 660px;
    }
  }
}
</style>
