<template>
  <FadeTransition :key="registerData.step" :duration="200" origin="center top">
    <section class="proceso-registro">
      <FormRegistro
        v-if="registerData.step == 1"
        @sendChatbotWeb="web = $event"
      />
      <FirstBot
        v-if="registerData.step == 2 && logged == 'true'"
        :web="web"
      />
      <IntegrationCMS v-if="registerData.step == 3" />
      <!-- <Subscription /> -->
      <Implement v-if="registerData.step == 4" />
      <!-- Solo para registro desde CMS -->
      <RegisterComplete v-if="registerData.step == 5" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapMutations, mapState } from "vuex";
import FormRegistro from "@/views/V2/Auth/components/Form_Registro";
import FirstBot from "@/views/V2/Auth/components/FirstBot";
import Implement from "@/views/V2/Auth/components/Implement";
import Subscription from "../../Auth/components/Subscrtiption";
import IntegrationCMS from "@/views/V2/Auth/components/IntegrationCMS";
import RegisterComplete from '@/views/V2/Auth/integrations/RegisterComplete';

export default {
  name: "register",
  components: {
    FormRegistro,
    FirstBot,
    Implement,
    FadeTransition,
    Subscription,
    IntegrationCMS,
    RegisterComplete
},
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms_confirmation: false
      },
      web: "",
      validateToAbortView: false
    };
  },
  created() {
    let my_register = this.registerData;

    if (this.bot_id) {
      my_register.step =
        this.logged == "true"
          ? this.$router.push({
              name: "register",
              query: { activar: "activate", bot_id: this.bot_id }
            })
          : this.$router.push({
              name: "login",
              query: { login: "login", bot_id: this.bot_id }
            });
      my_register.step = 3;
    } else {
      my_register.step = this.logged == "true" ? my_register.step : 1;

      // validacion - usuario se intenta registrar pero ya tiene una cuenta logueada cierra las sesiones y lo devuelve al login
      if (this.logged == "true" && this.user.botIds.length > 0) {
        this.logout();
        this.$router.push("/login");
      }
    }
    this.setRegisterProcess(my_register);
  },
  mounted() {
    // smooth scroll desde una landing a otra
    if (this.$route.query.seccion) {
      this.scrollTo(2000);
    };
    this.userLeaveRegisterTab();
  },
  computed: {
    ...mapState(["logged", "registerData", "user", "ownerBots"]),

    bot_id() {
      return this.$route.query.bot_id;
    }
  },
  methods: {
    ...mapMutations(["setRegisterProcess", "cleanRegisterData", "cleanCheckoutData", "logout"]),

    userLeaveRegisterTab() {
      // Alert cuando el user actualice el sitio
      if (this.registerData.step == 2 || this.registerData.step == 3) {
        window.addEventListener('beforeunload', (event) => {
          if (event.isTrusted) {
            console.log("event->", event);
            event.preventDefault();
            event.returnValue = '';
            this.cleanRegisterData();
            this.cleanCheckoutData();
            this.logout();
          }
        });
      }
    },
    // smooth scroll
    scrollTo(millis) {
      this.$scrollTo(`#${this.$route.query.seccion}`, millis);
    },
  },
  watch: {
    $route(to, from) {
      this.scrollTo(2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.proceso-registro {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    height: 100%;
    margin: 4rem auto;
  }
}
</style>

