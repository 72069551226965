<template>
  <section class="card-interior">
    <!-- <div
      v-if="priceOffer"
      class="offer_box"
      ><p class="offer">Cyber -20%</p>
    </div> -->
    <div v-if="product.name !== 'corporate'" class="tooltip-custom fas fa-question-circle">
      <p class="tooltip_text">{{ recomendation }}</p>
    </div>
    <div v-else class="tooltip-custom head-card">
      <p class="tooltip_text" v-html="recomendation" />
      <div class="badge">
        <div class="star_circle">
          <i class="fas fa-star mr-0" />
        </div>
      </div>
    </div>
    <img class="plan_icon" :src="img_route" :alt="product.name" />
    <h2 class="mb-0">{{ product.name }}</h2>
    <template v-if="product.name != 'enterprise'">
      <p v-if="priceToShow != 0">
        {{
          platform_name == "shopify"
            ? period != "monthly"
              ? `Pago ${periodicity}. ${valuePerYear} USD por mes`
              : `Pago ${periodicity}`
            : `Pago ${periodicity}`
        }}.
      </p>
      <p v-else>Diviértete disfrutando de las características básicas de nuestro chatbot con IA.</p>
    </template>
    <p v-else class="mt-3">¿Necesitas algo especial? Contáctanos.</p>
    <h1
      v-if="product.name != 'enterprise'"
      class="price"
      :class="{ price_dolar: platform_name == 'shopify' }"
    >
      {{ priceToShow }} {{ platform_name == "shopify" ? "USD" : "UF" }}
    </h1>
    <!-- <p :class="priceOffer" class="before-price">{{ priceToShow }} UF.</p> -->
    <!-- <p v-if="plan.bill_plan_profiles.length && priceToShow != 0" class="info mt-3" -->
    <template v-if="product.name != 'enterprise'">
      <button
        v-if="lowerPrice"
        class="btn btn-base sm"
        :class="product.name !== 'corporate' ? '' : 'second'"
        @click="goToPlanRegister"
      >
        {{ currentSub != null && currentSub.product == product.name ? "Modificar" : "Lo quiero" }}
      </button>
    </template>
    <a
      v-else
      href="https://meetings.hubspot.com/gmanriquez"
      class="btn btn-base sm"
      >Agendar</a
    >
    <!-- <div class="card__box-slider px-3 buefy">
      <div v-if="plan.bill_plan_profiles.length && priceToShow != 0">
        <b-slider
          :min="1"
          :max="plan.bill_plan_profiles.length"
          :tooltip="false"
          rounded
          v-model="selectedValue"
        >
          <template v-for="val in plan.bill_plan_profiles.length">
            <b-slider-tick :value="val" :key="val"></b-slider-tick>
          </template>
        </b-slider>
      </div>
    </div> -->
    <div class="info_box">
      <!-- <p class="mb-3" v-if="plan.name == 'Plan Gratis'">
        <i class="fas fa-info-circle" />Si tu plan vence volverás automáticamente a este plan.
      </p> -->
      <label class="mb-0">Plan base incluye:</label>
      <p class="mb-0">
        Licencia para {{ product.licences | clp }} {{ product.licences == 1 ? "agente" : "agentes" }}.
      </p>
      <p class="mb-0">Nº de conversaciones: {{ product.conversations | clp }}</p>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: [
    "currentPlan",
    "currentSub",
    "product",
    "period",
    "img_route",
    "recomendation"
  ],

  data() {
    return {
      selectedValue: 0,
      profile: {},
      my_register: {},
      lowerPrice: true
    };
  },

  computed: {
    ...mapState(["selected_bot", "platform_name"]),

    valuePerYear() {
      return this.period != "monthly" ? (this.priceToShow / 12).toFixed(0) : "";
    },

    profileValues() {
      return this.profile;
    },

    // Esto cambia el valor de los planes en relación a su periodicidad mensual o anual.
    priceToShow() {
      let price = 0;
      price = this.platform_name == "shopify" ? this.product.usd_price : this.product.uf_price;

      if (this.period == "yearly") { price = (price * 12 * 0.8).toFixed(0); }

      return price;
    },

    periodicity() {
      if (this.period == "monthly") return "mensual";
      return "anual";
    }
  },

  methods: {
    ...mapMutations(["setRegisterProcess", "setCheckoutData"]),

    // Envía al siguiente paso de la compra
    goToPlanRegister() {
      // this.my_register.step = this.logged == "false" ? 1 : 2;
      // this.my_register.plan = this.plan.id;
      // this.my_register.channel = this.channel.id;
      // this.my_register.profile = this.selectedValue;
      // this.my_register.billPlan = this.plan.name;
      // this.my_register.period = this.period;
      // this.setRegisterProcess(this.my_register);

      this.setCheckoutData({
        plan_data: this.currentPlan,
        plan_period: this.period,
        bot_id: this.selected_bot.id,
        subscription: this.currentSub,
        product: this.product
      });

      let section = this.$route.query.seccion == "update" ? "update" : "new";

      this.$router.push({
        name: "dashboard-checkout-v2",
        query: {
          section
        }
      });
    }
  },

  watch: {
    // Se activa cuando se modifica el valor del plan.
    selectedValue(val) {
      const profiles = this.plan.bill_plan_profiles.sort((a, b) =>
        a.total_uf_monthly_price > b.total_uf_monthly_price ? 1 : -1
      ); // esto ordena por id ascendente en caso de venir desordenados
      this.profile = profiles[val - 1];

      if (this.qvo) {
        if (this.profile.total_uf_monthly_price <= this.qvo) this.lowerPrice = false;
        else this.lowerPrice = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-interior {
  .price_dolar {
    font-size: 1.75rem !important;
  }
  .offer_box {
    position: absolute;
    padding: 0.5rem 1rem;
    top: 55px;
    right: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #2981ef;
    // background-color: #FF6565;
    z-index: 10;

    .offer {
      color: #fff;
      font-weight: 700;
      margin: 0;
    }
  }
}
</style>
