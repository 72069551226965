<template>
  <section class="card-interior">
    <!-- <div
      v-if="priceOffer"
      class="offer_box"
      ><p class="offer">Cyber -20%</p>
    </div> -->
    <div v-if="plan.name !== 'Plan Corporate'" class="tooltip-custom fas fa-question-circle">
      <p class="tooltip_text">{{ recomendation }}</p>
    </div>
    <div v-else class="tooltip-custom head-card">
      <p class="tooltip_text" v-html="recomendation" />
      <div class="badge">
        <div class="star_circle">
          <i class="fas fa-star mr-0" />
        </div>
      </div>
    </div>
    <img class="plan_icon" :src="img_route" :alt="plan.name" />
    <h2 class="mb-0">{{ plan.name }}</h2>
    <template v-if="plan.name != 'Plan Enterprise'">
      <p v-if="priceToShow != 0">
        {{
          platform_name == "shopify"
            ? period != "monthly"
              ? `Pago ${periodicity}. ${valuePerYear} USD por mes`
              : `Pago ${periodicity}`
            : `Pago ${periodicity}`
        }}.
      </p>
      <p v-else>
        Diviértete disfrutando de las características básicas de nuestro chatbot con IA.
      </p>
    </template>
    <p v-else class="mt-3">¿Necesitas algo especial? Contáctanos.</p>
    <h1
      v-if="plan.name != 'Plan Enterprise'"
      class="price"
      :class="{ price_dolar: platform_name == 'shopify' }"
    >
      {{ priceToShow }} {{ platform_name == "shopify" ? "USD" : "UF" }}
    </h1>
    <template v-if="plan.name != 'Plan Enterprise'">
      <button
        v-if="lowerPrice"
        class="btn btn-base sm"
        :class="plan.name != 'Plan Corporate' ? '' : 'second'"
        @click.prevent="goToPlanRegister"
      >
        Lo quiero
      </button>
    </template>
    <a
      v-else
      href="https://meetings.hubspot.com/gmanriquez"
      class="btn btn-base sm"
      >Agendar</a
    >
    <div class="info_box">
      <label class="mb-0">Plan base incluye:</label>
      <p class="mb-0">
        Licencia para {{ plan.agents | clp }} {{ plan.agents == 1 ? "agente" : "agentes" }}.
      </p>
      <p class="mb-0">Nº de conversaciones: {{ channel.conversations | clp }}</p>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import dashboard_api from "@/dashboard_api";
import chatbot_api from "@/chatbot_api";

export default {
  props: ["plan", "channel", "period", "img_route", "caracteristicas_info", "recomendation"],

  data() {
    return {
      profile: {},
      my_register: {},
      lowerPrice: true
    };
  },

  computed: {
    ...mapState(["platform_name", "registerData", "user"]),

    valuePerYear() {
      return this.period != "monthly" ? (this.priceToShow / 12).toFixed(0) : "";
    },

    // Esto cambia el valor de los planes en relación a su periodicidad mensual o anual.
    priceToShow() {
      console.log(this.plan);
      let price = 0;
      if (this.period == "monthly") {
        if (this.platform_name == "shopify") price = this.plan.dollar_price;
        else price = this.plan.price;
      } else {
        if (this.platform_name == "shopify") price = (this.plan.dollar_price * 12 * 0.8).toFixed(0);
        else price = (this.plan.price * 12 * 0.8).toFixed(0);
      }
      return price;
    },

    periodicity() {
      if (this.period == "monthly") return "mensual";
      return "anual";
    }
  },

  methods: {
    ...mapMutations(["setRegisterProcess"]),

    goToPlanRegister() {
      let self = this;
      self.my_register = self.registerData;
      self.$swal.showLoading();

      // Crear Plan
      dashboard_api
        .post("/admissible_plans", {
          base_plan_id: self.plan.id,
          channels: [self.channel.id],
          extra_licences: 0,
          price: self.priceToShow,
          period: self.period
        })
        .then(admissiblePlansResponse => {
          let daysLeft = self.platform_name == "shopify" ? 14 : 7;

          dashboard_api
            .post("/client_subscriptions", {
              bot_id: self.my_register.bot,
              user_id: self.user.id,
              admissible_plan_id: admissiblePlansResponse.data.id,
              status: true,
              days_left: daysLeft,
              test_period: true
            })
            .then(() => {
              if (self.platform_name == "shopify") {
                // Shopify
                self.shopifyPayment(admissiblePlansResponse.data);
              } else {
                // Flow
                dashboard_api
                  .post("/flow_requests/create_plan", admissiblePlansResponse.data)
                  .then(create_plan_response => {
                    dashboard_api
                      .post("/flow_requests/create_subscription", {
                        plan_id: create_plan_response.data.plan_id,
                        admissible_plan_id: admissiblePlansResponse.data.id,
                        customer_id: self.my_register.customerId,
                        bot_id: self.my_register.bot,
                        trial: daysLeft
                      })
                      .then(() => {
                        self.$swal({
                          icon: "success",
                          title: "Suscripción creada",
                          timer: 800,
                          showConfirmButton: false
                        });

                        if (self.platform_name == "jumpseller") {
                          self.completed = true;

                          chatbot_api
                            .post("/implementar", {
                              id: self.registerData.bot,
                              token: self.registerData.botToken,
                              token_access: self.$route.query.token_access
                            })
                            .then(response => {
                              // window.open("https://ia.databot.cl/dashboard");
                              self.getBots();
                              self.my_register.step = self.logged == "false" ? 1 : 4;
                              self.setRegisterProcess(self.my_register);
                            })
                            .catch(error => console.log(error));
                          // } else if (self.platform_name == "shopify") {
                          //   self.$router.push({
                          //     name: "dashboard",
                          //     query: {
                          //       shop: self.$route.query.shop,
                          //       access_token: self.$route.query.access_token,
                          //       token_access: self.$route.query.token_access,
                          //       platform: self.platform_name,
                          //       shop_domain: self.$route.query.shop_domain,
                          //     },
                          //   });
                        } else {
                          self.getBots();
                          self.my_register.step = self.logged == "false" ? 1 : 4;
                          self.setRegisterProcess(self.my_register);
                        }
                      })
                      .catch(error => console.error("error ->", error));
                  })
                  .catch(error => console.error("error -> ", error));
              }
            })
            .catch(error => console.error("error -> ", error));
        })
        .catch(error => console.error("error -> ", error));
      //     dashboard_api
      //       .post("/client_subscriptions", {
      //         bot_id: self.registerData.bot,
      //         user_id: self.user.id,
      //         admissible_plan_id: admissiblePlansResponse.data.id,
      //         status: true,
      //         days_left: daysLeft,
      //         test_period: true,
      //       })
      //       .then(() => {
      //         self.$swal({
      //           icon: "success",
      //           title: "¡Suscripción exitosa!",
      //           timer: 1000,
      //           showConfirmButton: false,
      //         });

      //         if (self.platform_name == "jumpseller") {
      //           self.completed = true;
      //         } else if (self.platform_name == "shopify") {
      //           self.$router.push({
      //             name: "dashboard",
      //             query: {
      //               shop: self.$route.query.shop,
      //               access_token: self.$route.query.access_token,
      //               token_access: self.$route.query.token_access,
      //               platform: self.platform_name,
      //               shop_domain: self.$route.query.shop_domain,
      //             },
      //           });
      //         } else {
      //           self.getBots();
      //           self.my_register.step = self.logged == "false" ? 1 : 4;
      //           self.setRegisterProcess(self.my_register);
      //         }

      //         chatbot_api
      //           .post("/implementar", {
      //             id: self.registerData.bot,
      //             token: self.registerData.botToken,
      //             token_access: self.$route.query.token_access,
      //           })
      //           .then((response) => {
      //             // window.open("https://ia.databot.cl/dashboard");
      //             self.getBots();
      //             self.my_register.step = self.logged == "false" ? 1 : 4;
      //             self.setRegisterProcess(self.my_register);
      //           })
      //           .catch((error) => console.log(error));
      //       })
      //       .catch((error) => console.error("error ->", error));
      //   })
      //   .catch((error) => console.error("error ->", error));
      // // FIn Crear Plan
    },

    shopifyPayment(plan) {
      // a shopify
      dashboard_api
        .post("/subscribe", {
          bot_id: this.registerData.bot,
          admissible_plan_id: plan.id,
          plan_name: plan.name,
          plan_price: plan.price,
          test: true
        })
        .then(response => {
          if (response.data.userErrors.length == 0) {
            // location.replace(response.data.confirmationUrl)
            window.open(response.data.confirmationUrl, "_blank");
          } else {
            this.$swal({
              icon: "error",
              title: "Ha ocurrido un problema",
              text: response.data.userErrors.map(error => error.message).join(", "),
              showConfirmButton: false
            });
          }
        })
        .catch();
    },

    async getBots() {
      this.bots = (await dashboard_api.get(`/bot/list/${this.user.id}?owner=my_bots`)).data;

      this.bots.sort((a, b) => (a.activated > b.activated ? -1 : 1));
      this.$store.commit("setOwnerBots", this.bots);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-interior {
  .price_dolar {
    font-size: 1.75rem !important;
  }
  .offer_box {
    position: absolute;
    padding: 0.5rem 1rem;
    top: 55px;
    right: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #2981ef;
    // background-color: #FF6565;
    z-index: 10;

    .offer {
      color: #fff;
      font-weight: 700;
      margin: 0;
    }
  }
}
</style>
