<template>
  <div class="field text-left">
    <br />
    <h2 class="label">Términos y condiciones</h2>
    <p class="mb-4">Los presentes Términos y Condiciones (los “Términos y Condiciones”) regulan el acceso y uso de
      los servicios de Databot y/o todas las soluciones ofrecidas por Databot Spa., una sociedad
      incorporada bajo las leyes de Chile, única propietaria de Databot (“Databot”), como así también
      el uso y acceso a su sitio web, plataforma digital (la “Plataforma”) y todos los servicios y
      aplicaciones (los “Servicios”). Los presentes Términos y Condiciones resultan aplicables a todos
      los visitantes, usuarios y personas que accedan, contraten o usen los Servicios. El uso de los
      Servicios incluirá, al menos, los presentes Términos y Condiciones.</p>
      <p class="mb-4">LEA ESTOS TÉRMINOS Y CONDICIONES DETENIDAMENTE ANTES DE ACCEDER A LA
      PLATAFORMA Y A USAR Y/O ACEPTAR LOS SERVICIOS. CUALQUIER PERSONA HUMANA
      Y/O JURÍDICA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES COMO LAS POLÍTICAS
      DE PRIVACIDAD, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE,
      DEBERÁ ABSTENERSE DE ACCEDER Y UTILIZAR LA PLATAFORMA COMO ASÍ TAMPOCO
      PODRÁ CONTRATAR LOS SERVICIOS.</p>
      <p class="mb-4">Los presentes Términos y Condiciones constituyen un acuerdo legal entre usted y Databot que
      regula los principales términos y condiciones de acceso y uso de la Plataforma como la
      contratación de los Servicios. Si usted no acepta dichos Términos y Condiciones, no podrá
      acceder a la Plataforma, como tampoco podrá contratar los Servicios. Los presentes Términos y
      Condiciones, junto con sus actualizaciones, modificaciones, términos adicionales y políticas
      relacionadas constituyen el acuerdo legal entre Databot y usted. Estos Términos y Condiciones
      sustituyen expresamente los acuerdos o compromisos previos celebrados entre las partes.
      Databot podrá en cualquier momento poner fin a estos Términos y Condiciones, como así
      también dejar de ofrecer o denegar en forma unilateral y sin necesidad de invocar causa alguna
      el acceso a la Plataforma y/o la prestación de los Servicios, ya sea en forma total o parcial y sin
      que ello de lugar a reclamo y/o indemnización alguna a su favor.</p>
      <p class="mb-4">Databot se reserva el derecho de modificar en forma unilateral los presentes Términos y
      Condiciones y/o las Políticas de Privacidad sin notificación ni aprobación previa.</p>
      <h2 class="label">PRIMERA: REGISTRACIÓN. CUENTA.</h2>
      <p class="mb-4">1.1 Para poder utilizar los Servicios, usted debe tener una cuenta de Databot o crear una nueva
      cuenta (la “Cuenta”). Usted podrá acceder a la Plataforma y/o utilizar los Servicios únicamente
      en la medida que se cumpla estrictamente con los presentes Términos y Condiciones y con la
      legislación aplicable.</p>
      <p class="mb-4">1.2 Al acceder y/o utilizar cualquiera de los Servicios y/o la Plataforma, usted acepta que
      quedará obligado por los presentes Términos y Condiciones. Si usted accede a la Plataforma o
      utiliza los Servicios en nombre de una organización, entidad o persona jurídica de cualquier tipo
      (la “Organización”), usted acepta y reconoce que la Organización, a través de usted, está de
      acuerdo con los presentes Términos y Condiciones, y que usted tiene la facultad de representar
      y obligar a la Organización.</p>
      <p class="mb-4">Asimismo, usted acepta y reconoce que los Servicios pueden cambiar de vez en cuando a
      medida que evolucionan, o a medida que se restan o añaden funciones o se realizan
      modificaciones a la Plataforma, lo que podrá suceder sin previo aviso.</p>
      <p class="mb-4">1.3 El uso y acceso a la Plataforma, página web, app y/o cualquier otro software que en el futuro
      sea desarrollado por Databot, como así también la contratación de los Servicios, se encuentra
      estrictamente prohibido por cualquier persona menor de edad de acuerdo a la legislación
      aplicable.</p>
      <p class="mb-4">1.4 Usted será el único responsable por la actividad que ocurra en su cuenta de Databot
      (incluyendo, sin que ello implique limitación alguna, datos de terceras personas).</p>
      <p class="mb-4">1.5 Usted podrá crear una cuenta de Databot por intermedio de cualquier método de
      registración que Databot le provea de tiempo en tiempo en forma unilateral. Será
      responsabilidad suya crear una clave personal de seguridad para el ingreso a su Cuenta, como
      así también mantener en forma segura su clave de acceso a su Cuenta de Databot, debiendo
      notificar en forma inmediata a Databot cualquier uso no autorizado de su Cuenta de Databot o
      cualquier otra violación en su Cuenta. Por medio de los presentes Términos y Condiciones, usted
      se compromete a no divulgar su contraseña a terceros. En caso de que Databot detecte o
      razonablemente considere que distintas Cuentas contienen datos coincidentes o relacionados,
      podrá cancelar, suspender o inhabilitar todas las Cuentas relacionadas sin que ello implique
      derecho a reclamo a alguno por parte suya y/o los titulares de las demás Cuentas. Usted será el
      único responsable por el cuidado de su Cuenta, y por mantener sus claves de acceso protegidas.
      Databot no será responsable por la pérdida o daño causado por el incumplimiento de los
      requisitos aquí mencionados.</p>
      <p class="mb-4">1.6 Cada Cuenta es única e intransferible, motivo por el cual Usted no podrá usar cuentas de
      Databot de otros usuarios y/o clientes de Databot sin la autorización necesaria para ello, la que
      deberá al menos contar con la autorización de dicho usuario y de Databot en forma previa y por
      escrito.</p>
      <p class="mb-4">1.7 Usted acepta y reconoce que Databot no será responsable por cualquier pérdida o daño
      sufrido por usted o por terceros derivado de cualquier incumplimiento suyo a los presentes
      Términos y Condiciones.</p>
      <p class="mb-4">1.8 En caso de solicitar la registración de una cuenta de Databot, se le solicitará cierta
      información (entre ella, datos de contacto, descripción del servicio, cantidad aproximada de
      cliente, etc.) como parte del proceso de registración para el acceso a la Plataforma de Databot, o
      para continuar con el uso y contratación de los Servicios, la cual deberá ser exacta. En tal
      sentido, Usted garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y
      autenticidad de dichos datos. Los datos que usted proporcione se integrarán en una base de
      datos personales de la que es responsable Databot. Databot podrá solicitar informes a distintas
      bases de datos públicas o privadas en relación a información y/o datos correspondientes a usted.
      Para más información consultar la Política de Privacidad de Databot.</p>
      <p class="mb-4">1.9 Databot a su exclusiva discreción podrá denegar el registro suyo y/o darlo de baja en
      cualquier momento y con propia autonomía, sin que ellos genere derecho a reclamo y/o
      indemnización alguna por parte suya.1.10. Los Servicios serán provistos en base a una
      suscripción mensual y se abonarán de acuerdo a lo establecido en la cláusula Cuarta.</p>
      <h2 class="label">SEGUNDA: EL USO DE LOS SERVICIOS.</h2>
      <p class="mb-4">2.1 A través de la Plataforma y mediante la contratación de los Servicios, usted podrá dialogar
      con terceras personas o sus clientes en forma automática, dando respuesta a las consultas que
      se hagan por dichas partes o mismo realizando las consultas que usted considere necesario
      relacionados con la prestación de sus servicios, todo ello según la especificación correspondiente
      que usted nos indique.</p>
      <p class="mb-4">2.2 La conversación entre usted y sus clientes y/o potenciales clientes puede tener ilimitadas
      interacciones o aquellas que usted solicite, y se considerará finalizada en los siguientes casos: (a)
      inactividad por parte del usuario por el plazo de cinco (5) minutos, (b) derivación de la
      conversación a otro canal (formulario externo, operador telefónico, etc.), (c) opción activa de
      cierre de conversación por ventana en la ventana de chat; pudiendo asimismo finalizar cuando el
      flujo de diálogo configurado llega a su fin.</p>
      <p class="mb-4">2.3 Es condición esencial que los Servicios sean utilizados en relación a su negocio. Por esta
      razón, usted no estará autorizado a usar los Servicios con propósitos diferentes, pudiendo
      Databot suspender en forma parcial o total su cuenta, como cancelar la prestación de los
      Servicios en forma inmediata sin que ellos implique derecho a reclamo alguno de su parte.</p>
      <h2 class="label">TERCERA: USO DE LA PLATAFORMA.</h2>
      <p class="mb-4">3.1 Databot tendrá las facultades para denegar o restringir el acceso o posterior uso de la
      Plataforma a cualquier persona humana o de existencia ideal bajo su exclusivo criterio, sin
      generar ello posibilidad de reclamar perjuicio alguno por parte de dichas personas. Databot no
      será responsable si usted no cuenta con dispositivos compatibles con el uso de la Plataforma y/o
      necesarios para la contratación de los Servicios. Usted se compromete a hacer un uso adecuado
      y lícito de la Plataforma de conformidad con la legislación aplicable, los presentes Términos y
      Condiciones, la moral y buenas costumbres generalmente aceptadas y el orden público. No
      obstante lo anterior, al acceder y utilizar la Plataforma y durante la contratación de los Servicios,
      usted acuerda y se obliga a:</p>
      <p class="mb-4">(i) Contratar el Servicio para su uso personal, aceptando que no se encuentra autorizado ni tiene
      las facultades necesarias para ceder, cualquiera sea la forma, su Cuenta a un tercero.</p>
      <p class="mb-4">(ii) No autorizar a terceros a usar su Cuenta, haciéndose completamente responsable por los
      actos que cualquier otra persona humana o jurídica realice a su nombre con o sin su
      consentimiento.</p>
      <p class="mb-4">(iii) No ceder ni transferir de otro modo su cuenta a ninguna otra persona o entidad legal.
      (iv) No utilizar una cuenta que esté sujeta a cualquier derecho de una persona que no sea usted
      sin la autorización adecuada de acuerdo a lo previsto en los presentes Términos y Condiciones.</p>
      <p class="mb-4">(vi) No tratar de dañar el Servicio o la Plataforma de ningún modo, ni acceder a recursos
      restringidos en la Plataforma.</p>
      <p class="mb-4">(vii) Guardar de forma segura y confidencial la contraseña de su Cuenta y cualquier identificación
      facilitada para permitirle el acceso y uso de la Plataforma como así también la contratación de
      los Servicios.</p>
      <p class="mb-4">(ix) No intentar acceder, utilizar y/o manipular los datos de Databot, sus clientes, afiliados,
      usuarios o terceros.</p>
      <p class="mb-4">(x) No introducir ni difundir virus informáticos o cualesquiera otros sistemas físicos o lógicos que
      sean susceptibles de provocar daños en la Plataforma y/o dispositivos de Databot, terceros,
      clientes, usuarios y/o aliados.</p>
      <p class="mb-4">(xi) Cumplir con toda la legislación que le resulte aplicable para la contratación de los Servicios e
      interacción con sus clientes y/o potenciales clientes, entre ellas, sin que implique limitación
      alguna, la protección de datos personales en función de la jurisdicción donde desea utilizar los
      Servicios.</p>
      <p class="mb-4">(xii) no vender, efectuar actos de disposición de cualquier tipo, prestar, entregar, licenciar o
      sublicenciar, transferir o dar en uso, ya sea de forma parcial o total, temporaria o permanente, de
      manera gratuita u onerosa cualquiera de los Servicios a terceras partes, salvo expresa
      autorización por escrito de Databot.</p>
      <p class="mb-4">(xiii) no utilizar los Servicios para fines ilegales, en violación de cualquier ley aplicable o para la
      promoción de actividades ilegales.</p>
      <p class="mb-4">(xiv) no hacerse pasar por otras personas a través de los Servicios o de cualquier otra forma
      falsificar su asociación a alguna persona o entidad de una manera que pretenda confundir o
      engañar a los demás.</p>
      <p class="mb-4">(xv) no publicar o subir información privada o de identificación personal de terceras personas, tal
      como números de tarjetas de crédito, dirección o números de documentos de identidad, sin
      expresa autorización y permiso.</p>
      <p class="mb-4">(vii) no enviar comunicaciones no solicitadas, promociones, anuncios, o correo no deseado.</p>
      <p class="mb-4">(xvi) no acceder, manipular o utilizar áreas no públicas de los Servicios, los sistemas informáticos
      de Databot o los sistemas de soporte técnico técnicos de los proveedores de Databot.</p>
      <p class="mb-4">(xvii) no analizar, escanear o poner a prueba la vulnerabilidad de cualquier sistema o red o
      incumplir o eludir las medidas de seguridad o autenticación.</p>
      <p class="mb-4">(xviii) no acceder o buscar en los Servicios por algún otro medio distinto a las interfaces públicas
      de Databot.</p>
      <p class="mb-4">(xix) no falsificar cualquier TCP / IP o la información del encabezado de cualquier correo
      electrónico o publicación, o de cualquier manera utilizar los Servicios para enviar información
      alterada, engañosa o falsa.</p>
      <p class="mb-4">(xx) no interferir o interrumpir el acceso de cualquier usuario, host o red, incluyendo, sin
      limitación, el envío de virus, envío de spam, mail-bombing a los Servicios, o encriptar la
      Propiedad Intelectual del Cliente de tal manera que interfieran con o creen una carga excesiva
      para los Servicios. Los usuarios internacionales se comprometen a cumplir con todas las leyes
      locales e internacionales sobre conducta y conocimiento aceptable.
      Databot podrá investigar y/o suspender su cuenta si usted viola cualquiera de las reglas
      anteriores. Adicionalmente, Databot se reserva el derecho de dar de baja inmediatamente su
      cuenta sin previo aviso en el caso de que, a su juicio único y absoluto, usted viole los presentes
      Términos y Condiciones, o abuse del uso de sus Servicios.</p>
      <p class="mb-4">Usted acepta que no usará los Servicios de forma contraria a la buena fe, a las políticas públicas
      y a la legislación vigente.</p>
      <h2 class="label">CUARTA: PAGO DE LOS SERVICIOS.</h2>
      <p class="mb-4">4.1 Las tarifas aplicables al Servicio serán aquellas que en forma unilateral Databot decida.
      Usted acepta que sea Databot quien fije dichas tarifas. Las tarifas por la contratación de los
      Servicios serán cobradas por Databot, debiendo usted realizar el pago con anterioridad al inicio
      de la prestación de los Servicios correspondiente al siguiente ciclo mensual o anual (que puede
      no ser calendario), según corresponda, mediante tarjeta de crédito, transferencia bancaria a una
      cuenta que Databot indique o cualquier otra forma de pago a acordar entre las Partes, debiendo
      Databot emitir la factura correspondiente. Si usted incumpliera con esta obligación, Databot
      tiene el derecho a denegar la prestación de los Servicios sin que ello implique derecho a
      indemnización o reclamo alguno a su favor.</p>
      <h2 class="label">QUINTA: LICENCIA.</h2>
      <p class="mb-4">5.1 Sujeto a los presentes Términos y Condiciones y siempre y cuando usted tenga registrada
      una cuenta de Databot y se encuentre al día con los pagos del Servicio de acuerdo a lo previsto
      en la cláusula cuarta anterior, Databot le otorga a usted una licencia para el uso de los Servicios,
      la cual será no exclusiva y por tiempo limitado a la contratación de los Servicios, no pudiendo
      usted sublicenciar la misma a terceras partes. Databot se reserva todos los derechos no
      otorgados expresamente en los presentes Términos y Condiciones, pudiendo Databot terminar
      la presente licencia en cualquier momento sin necesidad de causa alguna, y sin que ello le
      otorgue a usted derecho a indemnización y/o reclamo alguno. La presente licencia no implica
      cesión de titularidad alguna a su favor de los derechos de Databot.</p>
      <h2 class="label">SEXTA: PROPIEDAD INTELECTUAL.</h2>
      <p class="mb-4">6.1 Todos los derechos, títulos e intereses sobre los Servicios (excluyendo la Propiedad
      Intelectual del Cliente (conforme se define más abajo)) son y seguirán siendo propiedad
      exclusiva de Databot y sus licenciantes. Los Servicios están protegidos por derechos de autor,
      marca registrada y otras leyes que resulten aplicables, tanto en Chile como en el extranjero. La
      contratación de los Servicios y la licencia otorgada por los presentes Términos y Condiciones no
      implica bajo ninguna circunstancia que usted tenga derecho a usar el nombre de Databot y/o
      cualquiera sus marcas comerciales, logotipos, nombres de dominio y otras características
      distintivas de marca. Las observaciones, opiniones, sugerencias o comentarios que usted pueda
      proporcionar en relación con los Servicios, serán meramente informativas pudiendo en forma
      unilateral descartar o no dichas recomendaciones, como así también utilizar o no esas
      observaciones, opiniones, comentarios o sugerencias del modo que Databot considere adecuado
      sin que ello genere ninguna obligación para con usted. Sin perjuicio de lo antedicho, Databot
      podrá incluir dentro de la ventana del chat de Databot, o en su defecto, debajo de la ventana del
      chat, el logotipo de Databot, incluyendo un link al sitio web de Databot.</p>
      <p class="mb-4">6.2 El software utilizado para brindar los Servicios en su conjunto, así como cada uno de sus
      elementos integrantes, sus actualizaciones, código fuente, nuevas versiones, procesos,
      imágenes, animaciones, módulos, videos, audio, texto, y algoritmos o subprogramas
      incorporados a los mismos, así como los materiales impresos o en formato electrónico o digital
      que los acompañan, y toda copia de los mismos, forman parte de la propiedad intelectual de
      Databot quien retiene todos sus derechos, cuya utilización se le permite a usted dentro de los
      límites estipulados en los presentes Términos y Condiciones. Dichos derechos están protegidos
      por leyes y disposiciones a nivel nacional e internacional, así como leyes y tratados
      internacionales de derechos de autor y derechos sobre la propiedad intelectual. Usted se obliga
      a no remover ni a borrar los avisos de derechos de autor, marcas, nombres comerciales, y en
      general ningún aviso que se encuentre en la documentación relativa al mismo.</p>
      <p class="mb-4">6.3 Usted reconoce y acepta que Databot podrá incluir a usted o su Organización dentro del
      listado público de clientes de Databot, para lo cual podrá identificarlo mediante la utilización de
      sus marcas, logos y/o nombre comercial.</p>
      <h2 class="label">SÉPTIMA: PROPIEDAD INTELECTUAL DEL CLIENTE.</h2>
      <p class="mb-4">7.1 Entre otras utilidades, los Servicios le permiten a usted crear, publicar o subir información,
      textos, gráficos u otros materiales (la “Propiedad Intelectual del Cliente”) y compartirlo con
      terceros. Usted conservará la propiedad de la Propiedad Intelectual del Cliente, y reconoce que
      Databot no tiene ni tendrá ninguna responsabilidad si usuarios o terceros copian, modifican,
      re-transmiten, o difunden la Propiedad Intelectual del Cliente. Usted entiende y acepta que la
      publicación de la Propiedad Intelectual del Cliente en los Servicios no es un sustituto del registro
      ante la autoridad competente en materia de derechos de autor o ninguna otra entidad de
      derechos de autor. En tal sentido, usted deberá analizar con sus propios asesores en forma
      cuidadosa lo que usted elija compartir a través de los Servicios.</p>
      <p class="mb-4">7.2 Usted se compromete a no crear, subir o publicar la Propiedad Intelectual del Cliente que: (a)
      pueda crear un riesgo de daño, pérdida, lesiones físicas o mentales, sufrimiento emocional,
      muerte, discapacidad, desfiguración o enfermedad física o mental tanto a usted como a
      cualquier otra persona o cualquier animal, (b) pueda crear un riesgo de pérdida o daño a
      cualquier persona o propiedad, (c) tenga por objeto dañar o explotar a menores de edad
      exponiéndolos a Propiedad Intelectual del Cliente inapropiada, solicitándoles información
      personal o de cualquier otro tipo, (d) pueda constituir o contribuir a un delito o agravio de
      cualquier tipo, (e) contenga cualquier información o Propiedad Intelectual del Cliente que se
      considere ilegal, dañino, abusivo, racial o étnicamente ofensivo, difamatorio, invasivo de la
      privacidad personal, acosador o humillante para otras personas, calumnioso, amenazante o
      profano, (f) contenga cualquier información o Propiedad Intelectual del Cliente que sea ilegal
      (incluyendo, pero no limitado a, la divulgación de información confidencial por disposición de ley
      o en virtud de información comercial de terceros), (g) contenga cualquier información o
      Propiedad Intelectual del Cliente que usted no tenga derecho a difundir, o (h) contenga cualquier
      información o Propiedad Intelectual del Cliente que sepa que es incorrecta o desactualizada.
      Usted acepta que cualquier Propiedad Intelectual del Cliente que publique no podrá violar
      ningún derecho de terceros, incluyendo, sin limitaciones, los derechos de propiedad intelectual o
      derechos de privacidad.</p>
      <p class="mb-4">7.3 Databot se reserva el derecho, pero de ninguna manera se obliga, a rechazar y/o eliminar
      cualquier Propiedad Intelectual del Cliente que considere, a su entera discreción, que viole estas
      disposiciones, sin que ello le otorgue a usted derecho a reclamo alguno.</p>
      <p class="mb-4">7.4 Usted conservará la propiedad sobre la Propiedad Intelectual del Cliente, pero autoriza a
      Databot a usar, copiar, reproducir, procesar, adaptar, modificar, publicar, transmitir, editar,
      traducir, mostrar y distribuir dicha Propiedad Intelectual del Cliente en relación con la prestación
      de los Servicios, otorgando a Databot una licencia gratuita, no exclusiva y a perpetuidad sobre la
      Propiedad Intelectual del Cliente.</p>
      <p class="mb-4">7.5 Usted acepta que Databot podrá utilizar la Propiedad Intelectual del Cliente como así las
      preguntas y respuestas, interacciones y cualquier contenido de su propiedad para mejorar sus
      procesos y algoritmos de entendimiento e inteligencia artificial.
      Usted acepta que Databot pueda modificar o adaptar la Propiedad Intelectual del Cliente a fin de
      transmitirla, mostrarla o distribuirla a través de redes sociales y/o diferentes medios de
      comunicación. Como así también autoriza a Databot a realizar cambios sobre la Propiedad
      Intelectual del Cliente, cuando estos sean necesarios, a criterio de Databot, para adaptar dicha
      Propiedad Intelectual del Cliente a cualquier requisito o limitación de cualquier red, dispositivo,
      servicio o medio de comunicación sin que ello le otorgue a usted derecho a reclamo alguno.</p>
      <p class="mb-4">7.6 Databot se reserva el derecho, pero de ninguna manera se encuentra obligado, a suprimir o
      rechazar la distribución de cualquier Propiedad Intelectual del Cliente en los Servicios. Además
      se reserva el derecho de acceder, leer, preservar y revelar cualquier información si considera
      razonablemente que es necesario para (i) cumplir con cualquier ley, regulación, proceso legal o
      solicitud gubernamental aplicable, (ii) hacer cumplir los presentes Términos y Condiciones,
      incluyendo la investigación de posibles violaciones a los mismos, (iii) detectar, prevenir, o de
      cualquier modo abordar asuntos de fraude, seguridad o técnicos, (iv) responder a solicitudes de
      asistencia de los usuarios, o (v) proteger los derechos, propiedad o seguridad de Databot, sus
      usuarios y del público en general.</p>
      <h2 class="label">OCTAVA: USO DE LA PROPIEDAD INTELECTUAL DEL CLIENTE.</h2>
      <p class="mb-4">8.1 La Propiedad Intelectual del Cliente incluida o a ser incluida en los Servicios, ya sea pública o
      transmitida por los usuarios de forma privada, es de exclusiva responsabilidad de quien originó
      dicha Propiedad Intelectual del Cliente. Databot no avala, ni apoya, ni representa ni garantiza la
      integridad, veracidad, exactitud o confiabilidad de ninguna Propiedad Intelectual del Cliente
      creada, cargada o publicada a través de los Servicios ni avala ninguna opinión expresada a
      través de los Servicios. Databot no tiene capacidad de supervisar y/o controlar la Propiedad
      Intelectual del Cliente creada, cargada o publicada a través de los Servicios, por lo que no puede
      asumir responsabilidad en relación a tal Propiedad Intelectual del Cliente. Por lo que, si usted
      utiliza o depende de alguna Propiedad Intelectual del Cliente o materiales creados, cargados o
      publicados a través de los Servicios, será bajo su exclusiva responsabilidad, debiendo mantener
      indemne a Databot en todo momento por dicho Propiedad Intelectual del Cliente. Bajo ninguna
      circunstancia Databot será responsable de la Propiedad Intelectual del Cliente, pérdida o daño
      alguno, de cualquier tipo incurridos como resultado del uso de cualquier Propiedad Intelectual
      del Cliente en el Servicio.</p>
      <p class="mb-4">8.2 Usted acepta que es el único responsable por el uso que haga de los Servicios, como así
      también por cualquier Propiedad Intelectual del Cliente que usted proporcione, de las
      consecuencias de los mismos, incluyendo el uso de la Propiedad Intelectual del Cliente por otros
      usuarios y terceros. Usted comprende que si no tiene el derecho de subir la Propiedad Intelectual
      del Cliente a los Servicios, si lo hace puede estar sujeto a responsabilidad legal. Databot no será
      responsable por el uso que haga de la Propiedad Intelectual del Cliente de acuerdo con los
      presentes Términos y Condiciones. Usted manifiesta y garantiza que tiene todos los derechos,
      facultades y autoridad necesarios para conceder los derechos otorgados en el presente
      documento sobre cualquier Propiedad Intelectual del Cliente que usted envíe.</p>
      <h2 class="label">NOVENA: USO Y GARANTÍA DE LA PLATAFORMA.</h2>
      <p class="mb-4">9.1 Databot no garantiza la continuidad del funcionamiento y/o acceso a la Plataforma y/o a los
      Servicios. En consecuencia, Databot no será en ningún caso responsable por cualesquiera daños
      y perjuicios que puedan derivarse de (i) la falta de disponibilidad o accesibilidad a la Plataforma
      y/o prestación de los Servicios, ya sea por fallas de Databot o ajenas a ésta; (ii) la interrupción en
      el funcionamiento de la Plataforma y/o los Servicios o fallos informáticos, averías telefónicas,
      desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas
      telefónicas, centros de datos, en el sistema de Internet o en otros sistemas electrónicos,
      producidos en el curso de su funcionamiento; y (iii) otros daños que puedan ser causados por
      terceros mediante intromisiones no autorizadas ajenas o no al control de Databot.</p>
      <p class="mb-4">9.2 Databot no garantiza la ausencia de virus informáticos ni de otros elementos en la
      Plataforma introducidos por terceros ajenos a Databot que puedan producir alteraciones en los
      sistemas electrónicos y/o lógicos suyos y/o en los documentos electrónicos y ficheros
      almacenados en sus sistemas. En consecuencia, Databot no será en ningún caso responsable de
      cualesquiera daños y perjuicios de toda naturaleza que pudieran derivarse de la presencia de
      virus y/u otros elementos que puedan producir alteraciones en los sistemas físicos y/o lógicos,
      documentos electrónicos y/o ficheros suyos.</p>
      <p class="mb-4">9.3 Databot adopta diversas medidas de protección para proteger la Plataforma y los contenidos
      contra ataques informáticos de terceros. No obstante, Databot no garantiza que terceros no
      autorizados no puedan conocer las condiciones, características y circunstancias en las cuales
      usted accede a la Plataforma y/o se prestan los Servicios. En consecuencia, Databot no será en
      ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no
      autorizado.</p>
      <p class="mb-4">9.4 Mediante el acceso a la Plataforma y consecuente aceptación de los presentes Términos y
      Condiciones, Ud. declara que mantendrá indemne a Databot, su sociedad matriz, afiliadas,
      representantes legales, directores, socios, empleados, consultores y agentes frente a cualquier
      reclamo derivado del (i) incumplimiento suyo de cualquier disposición contenida en los presentes
      en los Términos y Condiciones y/o de cualquier ley o regulación aplicable a las mismas, (ii)
      incumplimiento o violación de los derechos de terceros incluyendo, a título meramente
      enunciativo, otros usuarios y/o aliados de Databot; y (iii) incumplimiento del uso permitido de la
      Plataforma y de los Servicios.</p>
      <h2 class="label">DÉCIMA: PRIVACIDAD.</h2>
      <p class="mb-4">10.1 Databot se preocupa por la privacidad de sus usuarios y de quienes acceden a la
      Plataforma. Databot recoge, utiliza y comparte información de identificación personal e
      información de identificación no personal, tal como se describe en su Política de Privacidad.
      Usted acepta que al acceder a la Plataforma y/o al utilizar los Servicios está de acuerdo con la
      recopilación de dicha información, y que sus datos de carácter personal sean recogidos, usados,
      transferidos y procesados de acuerdo a la Política de Privacidad y a la legislación aplicable.</p>
      <h2 class="label">DECIMOPRIMERA: ENLACES DE TERCEROS.</h2>
      <p class="mb-4">11.1 La Plataforma y/o los Servicios pueden contener enlaces a sitios web de terceros,
      anunciantes, servicios, ofertas especiales u otros eventos o actividades que no son de propiedad
      o controlados por Databot. Databot no avala, ni asume ninguna responsabilidad por cualquiera
      de estos sitios de terceros, información, materiales, productos o servicios. Si usted accede a un
      sitio web de terceros desde los Servicios, lo hace bajo su propio riesgo y entiende que los
      presentes Términos y Condiciones y nuestra Política de Privacidad no se aplicará al uso de
      dichos sitios. Usted libera expresamente a Databot de cualquier y toda responsabilidad derivada
      del uso de cualquier sitio web, servicio o contenido de terceros y acepta que sus relaciones con
      cualquier sitio web de terceros, servicio o contenido es sólo entre usted y dichos terceros. Usted
      acepta que Databot no es ni será responsable por cualquier pérdida o daño de cualquier tipo que
      pueda sufrir en sus relaciones con terceros, no teniendo usted derecho a reclamo alguno frente a
      Databot.</p>
      <h2 class="label">DECIMOSEGUNDA: INDEMNIDAD.</h2>
      <p class="mb-4">12.1 Usted acepta mantener indemne a Databot, sus licenciatarios, licenciantes, accionistas,
      directores, funcionarios, empleados, contratistas, agentes y usuarios contra todo reclamo, daños,
      obligaciones, pérdidas, responsabilidades, costos o deudas y gastos (incluyendo pero no
      limitado a los honorarios de abogados) que surjan del uso y acceso a la Plataforma como así
      también de la contratación de los Servicios, incluido pero no limitado a cualquier reclamo
      originado por: (a) datos o Propiedad Intelectual del Cliente transmitidos o recibidos por usted, (b)
      el acceso o utilización de la Cuenta y/o de los Servicios por terceros con su nombre de usuario y
      contraseña, (c) la violación de los presentes Términos y Condiciones y/o de cualquier norma o
      reglamento aplicable.</p>
      <h2 class="label">DECIMOTERCERA: INFORMACIÓN CONFIDENCIAL.</h2>
      <p class="mb-4">13.1 Usted se obliga a proteger como confidencial y no divulgar a tercero alguno ninguna
      Información Confidencial (conforme este término se define más adelante) recibida de Databot,
      sus sociedades relacionadas o de cualquier otra forma descubierta por Usted en relación con o
      con motivo del uso de la Plataforma y/o la prestación de los Servicios. A estos efectos,
      “Información Confidencial” significa información que no es de público conocimiento y que es
      usada, desarrollada u obtenida por Databot y/o sus sociedades relacionadas, incluyendo pero sin
      limitarse a: (a) informaciones, procedimientos y datos obtenidos y/o desarrollados por Databot o
      sus sociedades relacionadas (incluyendo aquellos obtenidos con anterioridad a la fecha de inicio
      de la prestación de los Servicios) concernientes a negocios o asuntos de Databot o sus
      sociedades relacionadas, (b) productos o servicios, (c) costos y estructuras de precios, (d)
      análisis, (e) métodos de negocios y de contabilidad (f) software de computación, incluyendo
      sistemas operativos, solicitudes y listados de programación, (g) organigramas, manuales y
      documentación, (h) todos los métodos de producción, procesos, tecnología y secretos
      comerciales, y (i) toda otra información similar y relacionada, en cualquier forma. Asimismo,
      Usted se obliga a utilizar la Información Confidencial sólo a efectos del cumplimiento de sus
      obligaciones bajo estos Términos y Condiciones. A fin de mantener dicho marco de
      confidencialidad, Usted se compromete a tomar las medidas de seguridad que consideren
      necesarias y razonables y que incluyan, como mínimo, aquellas que utilicen para proteger su
      propia información confidencial, lo que no podrá ser inferior a lo establecido en la normativa
      aplicable. Usted deberá adoptar las medidas técnicas y organizativas que resulten necesarias
      para garantizar la seguridad y confidencialidad de la Información Confidencial, de modo de evitar
      su adulteración, pérdida, consulta o tratamiento no autorizado, y que permitan detectar
      desviaciones, intencionales o no, de Información Confidencial, ya sea que los riesgos provengan
      de la acción humana o del medio técnico utilizado.</p>
      <h2 class="label">DECIMOCUARTA: RELACIÓN ENTRE LAS PARTES.</h2>
      <p class="mb-4">14.1 El acceso y uso de la Plataforma como así la prestación de los Servicios no constituirá a
      usted en representante legal o agente de Databot.</p>
      <p class="mb-4">14.2 La única relación existente entre las Partes es la que resulta de los presentes Términos y
      Condiciones. Queda expresamente aclarado que ni usted ni sus empleados y/o subcontratistas
      (en caso de corresponder) son empleados de Databot sino que es un co-contratante
      independiente vinculado por una relación comercial con Databot relacionada a la prestación de
      los Servicios. En tal sentido, usted manifiesta y garantiza que posee experiencia, estructura y
      personal adecuados prestar los prestar sus servicios a terceros y para cumplir con la totalidad de
      las obligaciones asumidas en virtud de los presentes Términos y Condiciones.</p>
      <p class="mb-4">14.3 Todas las obligaciones laborales, previsionales y/o de la seguridad social que pudieran
      corresponderle de acuerdo a la legislación aplicable con relación a su personal, estarán a su
      cargo.</p>
      <p class="mb-4">14.4 Usted se obliga a mantener indemne a Databot de cualquier reclamo y/o demanda judicial o
      extrajudicial iniciada en su contra por sus empleados, agentes, dependientes y/o clientes por
      cuestiones laborales, previsionales o de la seguridad social.</p>
      <h2 class="label">DECIMOQUINTA: LIMITACIÓN DE RESPONSABILIDAD.</h2>
      <p class="mb-4">15.1 Los Servicios se proporcionan en las condiciones establecidas en los presentes Términos y
      Condiciones y de la manera en que se encuentran ofrecidos en la actualidad. El uso de los
      Servicios es bajo su propio riesgo. Los Servicios se ofrecen sin garantía de ningún tipo, ya sea
      expresa o implícita, incluyendo, pero no limitado a, las garantías de comercialización, idoneidad
      para un propósito particular o no infracción. Databot y sus proveedores de licencias no
      garantizan que: (a) los Servicios estarán disponibles en cualquier momento o lugar, (b) que los
      Servicios serán ininterrumpidos o seguros, (c) que cualquier defecto u error en los Servicios y/o
      en la Plataforma será corregido, (d) que los Servicios estarán libre de virus o de cualquier
      componente dañino.</p>
      <p class="mb-4">15.2 En ningún caso Databot, sus afiliados, agentes, directores, empleados o proveedores serán
      responsables por daños directos, indirectos, morales, accidentales, especiales, emergentes o
      ejemplares, incluyendo sin limitación los daños por pérdida de beneficios, lucro cesante ni de
      otras pérdidas intangibles, que resulten del uso o imposibilidad de usar o acceder a la
      Plataforma, su cuenta y/o los Servicios. Bajo ninguna circunstancia Databot es o será
      responsable por cualquier daño y/o pérdida como resultado de hacking, alteración o acceso no
      autorizado a su cuenta u otro uso de los Servicios o de su cuenta y de la información contenida
      en la misma, resultando aplicable lo previsto en los presentes términos y condiciones.</p>
      <p class="mb-4">15.3 Asimismo, en ningún caso Databot es o será responsables por: (i) aquello que le sea
      imputable a usted, (ii) el uso que Usted realice de los Servicios y/o la Plataforma, (iii) errores o
      imprecisiones en la Propiedad Intelectual del Cliente, (iv) los daños directos o indirectos que
      Usted pueda provocar a terceros, (v) lesiones o daños a la propiedad personal, de cualquier
      naturaleza, como resultado de su acceso o uso de los Servicios y/o la Plataforma, (vi) el acceso
      no autorizado o uso de servidores de Databot y/o de cualquier información personal mantenida
      en los mismos, (vi) cualquier interrupción o cese de la transmisión desde los Servicios y/o la
      Plataforma, (vii) cualquier error, virus, troyanos o similares que puedan ser transmitido a través
      de los Servicios y/o la Plataforma por parte de un tercero, (viii) cualquier error u omisión en
      cualquier Propiedad Intelectual del Cliente o cualquier pérdida o daño incurrido como resultado
      del uso de cualquier Propiedad Intelectual del Cliente publicada, enviada por correo electrónico,
      transmitida, o de cualquier forma puesta a disposición a través de los Servicios y/o la Plataforma,
      (ix) cualquier Propiedad Intelectual del Cliente de un usuario o cualquier conducta que resulte
      difamatoria, ofensiva, o ilegal efectuada por parte de terceros y/o (x) supuestos en los que los
      Servicios no cumplen con las necesidades del usuario.
      A todo evento, en ningún caso Databot, sus agentes, directores, empleados, proveedores o
      licenciantes serán responsables ante usted por reclamos, procedimientos, obligaciones, daños,
      pérdidas o gastos, por un monto superior al que usted abonó por los Servicios.</p>
      <p class="mb-4">15.4 Cualquier Propiedad Intelectual del Cliente descargada u obtenida a través del uso de los
      Servicios es descargada a su propio riesgo y usted será el único responsable de cualquier daño a
      su sistema informático y/o dispositivo móvil o por la pérdida de datos que resulte de tal descarga
      o del uso de cualquiera de los Servicios. Databot no garantiza, ni será responsable por los
      productos o servicios ofrecidos por terceros a través de la Plataforma y/o cualquiera de sus
      Servicios, como así tampoco Databot será parte de dicha operación y no monitoreará ninguna
      transacción entre usted y terceros proveedores de productos o servicios.</p>
      <p class="mb-4">15.5 Dadas las características de los Servicios, Databot podrá realizar en cualquier momento
      tareas rutinarias de mantenimiento, y/o mejoras de los Servicios, por esta razón y por otras
      posibles contingencias imprevisibles relacionadas a internet, Usted acepta riesgos e
      imperfecciones o indisponibilidad de los Servidores de terceros, y Usted renuncia expresamente
      a reclamar cualquier responsabilidad, contractual o extracontractual, daños y perjuicios a
      Databot por posibles fallos, lentitud o errores en el acceso y uso a la Plataforma, su cuenta y o
      prestación de los Servicios por causas que no sean imputables a Databot.</p>
      <p class="mb-4">15.6 Los Servicios disponen de una infraestructura de servicio de alta disponibilidad y auto
      escala, los que estarán disponibles los siete días de la semana, durante las 24 horas. Sin
      perjuicio de ello: (i) Databot se reserva el derecho de realizar suspensiones programadas al
      acceso y uso de la Plataforma, su cuenta y relacionados con la prestación de los Servicios, los
      cuales Databot podrá informar previamente por medio de correo electrónico o en la
      URL:http://____ o cualquier otro medio, con una anticipación mínima de 24 (veinticuatro) horas a
      los efectos de practicar tareas de mantenimiento, reparación u otras relacionadas a la prestación
      de los Servicios. Asimismo, Databot podrá realizar suspensiones no programadas cuando a su
      exclusivo criterio haya motivos de emergencia, sin que ello genere derecho a reclamo y/o
      indemnización alguna a su favor.</p>
      <p class="mb-4">15.7 Usted deberá notificar de forma inmediata a Databot todas las fallas de los Servicios y
      Databot informará a Usted acerca de la naturaleza de la falla del Servicio correspondiente y del
      tiempo de reparación previsible. De cara al restablecimiento del Servicio, Usted deberá prestar, si
      fuera necesario, todo el apoyo necesario y razonable que sea solicitado por Databot. Databot le
      informará lo antes posibles cuando el mal funcionamiento no esté relacionado al Servicio. Una
      vez reparada la eventual falla será avisado inmediatamente por Databot.</p>
      <p class="mb-4">15.8 Esta limitación de responsabilidad se aplica tanto si la supuesta responsabilidad se basa en
      un contrato, agravio, negligencia, estricta responsabilidad, o cualquier otra forma, aun cuando
      Databot haya sido advertido de la posibilidad de tales daños.</p>
      <h2 class="label">DECIMOSEXTA: MODIFICACIONES.</h2>
      <p class="mb-4">16.1 Databot se reserva el derecho de modificar los presentes Términos y Condiciones de tanto
      en tanto y siempre publicará en su sitio web la versión más actualizada. En caso de
      modificaciones en los presentes Términos y Condiciones, que a criterio de Databot, resulten
      fundamentales, Databot podrá notificar a usted por un método adicional (por ejemplo a través de
      un correo electrónico a la dirección de correo electrónico asociada a su cuenta). Los cambios a
      los presentes Términos y Condiciones pueden ser publicados en el sitio web o en la URL
      correspondiente a los Términos y Condiciones, por lo que Usted deberá verificar esas páginas
      con regularidad. Al continuar accediendo o utilizando los Servicios después de que las
      modificaciones se hayan sido publicadas, usted acepta que quedará vinculado por los términos y
      condiciones modificados. Si usted no está de acuerdo con los nuevos términos, deberá dejar de
      acceder a la Plataforma y utilizar los Servicios de inmediato.</p>
      <h2 class="label">DECIMOSÉPTIMA: TERMINACIÓN</h2>
      <p class="mb-4">17.1 En caso de terminación previo al vencimiento del ciclo mensual o anual contratado, Usted
      no tendrá derecho a reembolso alguno por el monto que corresponda al cliclo mensual o anual
      abonado en virtud de la presente.</p>
      <p class="mb-4">17.2 Si cualquiera de las partes incumpliere con sus obligaciones materiales bajo el presente, y
      no procediese a remediar dicho incumplimiento en un plazo de 30 días desde la notificación por
      escrito de la otra parte, la parte no incumplidora podrá rescindir la prestación de los Servicios y/o
      el uso de la Plataforma.</p>
      <p class="mb-4">17.3 Todas las disposiciones del presente que por su naturaleza deban subsistir cualquier
      terminación, incluyendo pero sin limitarse a aquellas disposiciones relativas a límites de
      responsabilidad, indemnidad, garantías o cuestiones relativas a derechos de propiedad,
      continuarán vigentes independientemente de la terminación del presente.</p>
      <p class="mb-4">17.4 En caso de que el Cliente desee dar por terminado el presente contrato, deberá notificarlo a Databot por escrito con una antelación mínima de sesenta (60) días corridos a la fecha en que desea que se haga efectiva la cancelación. En el caso que dicha notificación no sea realizada en un día y hora hábil, será considerada el día hábil siguiente a dicha notificación.
      
      La falta de notificación dentro del plazo establecido implicará la renovación automática del servicio por un período igual al inicialmente contratado.

      Databot se reserva el derecho a modificar el presente contrato o a suspender el Servicio de forma temporal o definitiva, previo aviso al Cliente con una antelación razonable, en caso de incumplimiento por parte del Cliente de cualquiera de las obligaciones establecidas en este contrato, o por causas de fuerza mayor.</p>
      
      <h2 class="label">DECIMOCTAVA: OTROS TÉRMINOS LEGALES.</h2>
      <p class="mb-4">18.1 Los presentes Términos y Condiciones y cualesquiera derechos y licencias aquí otorgados,
      no podrán ser transferidos o cedidos por usted, pero pueden ser asignados por Databot sin
      restricción o limitación alguna. Cualquier intento de transferencia o cesión por usted será nula de
      pleno derecho.</p>
      <p class="mb-4">18.2 En el improbable caso de que Databot no haya sido capaz de resolver una controversia que
      mantenga con usted después de intentar hacerlo de manera informal, tanto usted como Databot
      acuerdan que cualquier reclamo, disputa o controversia (excluyendo medidas cautelares o
      compensaciones equitativas solicitadas por Databot) que surja de o en conexión con, o en
      relación con los presentes Términos y Condiciones, o el incumplimiento o supuesto
      incumplimiento de los mismos (en conjunto, “Reclamos”), será sometido a los tribunales
      ordinarios de la Ciudad de santiago de Chile, Chile, sometiéndose las Partes a las leyes de Chile,
      salvo disposición en contrario en los presentes Términos y Condiciones. Nada en esta sección, se
      considerará como impedimento para Databot de solicitar medidas cautelares y/o
      compensaciones equitativas ante la autoridad competente, cuando sea necesario para proteger
      alguno de los intereses de propiedad de Databot. TODOS LOS RECLAMOS DEBEN SER
      LLEVADOS DE FORMA INDIVIDUAL POR LAS PARTES, Y NO COMO DEMANDANTES O
      MIEMBROS DE NINGUNA CLASE O COLECTIVO. USTED ACEPTA QUE, AL ACEPTAR ESTAS
      CONDICIONES, USTED Y Databot RENUNCIAN AL DERECHO A UN JUICIO POR JURADO O A
      PARTICIPAR EN UNA DEMANDA COLECTIVA O ACCIÓN DE CLASE.</p>
      <p class="mb-4">18.3 Estos Términos y Condiciones, junto con todas las enmiendas y los acuerdos adicionales
      que pueda realizar usted con Databot en relación a los Servicios, constituirán el acuerdo
      completo entre usted, su Organización (en caso de corresponder) y Databot. Si alguna
      disposición de estos Términos y Condiciones es considerada inválida por un tribunal de
      jurisdicción competente, la invalidez de dicha disposición no afectará la validez de las restantes
      disposiciones de los presentes Condiciones y Condiciones, los cuales permanecerán en pleno
      vigor y efecto.</p>
      <p class="mb-4">18.4 Ninguna renuncia a cualquiera de los términos de estos Términos y Condiciones se
      considerará una renuncia a otros términos no detallados expresamente, y la imposibilidad de
      Databot de hacer valer cualquier derecho o disposición en virtud de los presentes Términos y
      Condiciones podrá considerarse una renuncia a tal derecho o disposición.</p>
      <p class="mb-4">18.5 Databot podrá realizar notificaciones, si son requeridas por ley o para otros fines
      relacionados con el negocio, a través de una notificación general en la Plataforma, su correo
      electrónico, su Cuenta, carta o correo en papel o cualquier otro medio, a su exclusiva elección.</p>
      <p class="mb-4">18.6 En caso de requerir contactarse con Databot, a fin de evacuar cualquier consulta en relación
      a los presentes Términos y Condiciones podrá hacerlo escribiendo a hola@Databot.cl</p>
      <p class="mb-4">Los presentes Términos y Condiciones han sido actualizados por última vez en diciembre de
      2019
    </p>
  </div>
</template>

<script>
export default {
  props: ['show_modal_terms'],
};
</script>

<style lang="scss" scoped>
.field {
  padding: 0 2rem 2rem;
  height: 400px;
  overflow-y: scroll;

  .label {
    margin-bottom: 1.5rem;
  }
  .cruz {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
  }
}
</style>
