// import trae from "trae";
import axios from 'axios';
import environment from './environment';
import store from '@/store/store.js';
import { localStorageGetItem } from '@/utils/utils';

const payment = axios.create({
  baseURL: environment.payment,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/vnd.v1+json',
  },
});

// Add a response interceptor
payment.interceptors.response.use(
  (response) => {
    // Do something with response data
    // Checks if app is being used in mobile
    // if (
    //   response.config.url !== `${process.env.VUE_APP_API_URL}/token` &&
    //   response.config.url !== `${window.location.origin}/version.json`
    // ) {
    //   //   checkForUpdates();
    //   checkIfTokenNeedsRefresh();
    // }
    return response;
  },
  (error) => {
    // esto hace que si vence el token, se redireccione al login
    if (error.response.status === 401) {
      store.dispatch('logout');
      store.commit('cleanRegisterData');
    }
    if (error.response.status === 404) console.log('registro no encontrado');
    // Do something with response error
    return Promise.reject(error);
  },
);

export default payment;
