<template>
  <Modal
    class="modal-2"
    ancho="1000"
    alto="800"
    :show="showModal"
    @close="$emit('closeModal')"
    v-if="selectedVideo"
  >
    <template slot="header">
      <h1 class="titulo__modal mb-4" />
    </template>
    <template slot="content">
      <section class="show-tutorial">
        <SpinnerLoading v-show="loading" />
        <iframe
          @load="loading = false"
          v-show="!loading"
          class="iframe_box"
          :src="selectedVideo.video"
          :title="selectedVideo.name"
          width="700"
          height="350"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: ["showModal", "selected_platform"],

  data() {
    return {
      loading: false,
      tutorials: [
        {
          name: "Shopify",
          video: "https://www.youtube.com/embed/rgIt6_Mfw2Y?si=PqmVLhznnfO3Bav-",
        },
        {
          name: "Vtex",
          video: "https://www.youtube.com/embed/5f9fxhLGB3E?si=cIGamLXjhohqA6Mh",
        },
        {
          name: "Jumpseller",
          video: "https://www.youtube.com/embed/cx9RHT6T6fA?si=oMMLux02GL67ni6i",
        },
        {
          name: "Magento",
          video: "/img/logos/2-implementacion/magento.svg",
        },
        {
          name: "Woocommerce",
          video: "/img/logos/2-implementacion/woocommerce.svg",
        },
        {
          name: "Bsale",
          video: "/img/logos/2-implementacion/bsale-2.svg",
        },
      ]
    };
  },
  computed: {
    selectedVideo() {
      return this.tutorials.find(el => el.name == this.selected_platform.name);
    }
  }
};
</script>

<style lang="scss">
.integration_cms {
  .modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.show-tutorial {
  width: 100%;
  padding: 2rem 0;
  .iframe_box {
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  }
}
</style>
