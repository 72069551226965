<template>
  <section v-show="show" class="whatsapp-table">
    <h2 class="text-center pt-5 px-4 my-5">Precios de WhatsApp API por conversaciones 24 hrs.</h2>
    <div class="card">
      <div class="card_head">
        <div class="col">
          <label class="mb-0">País</label>
        </div>
        <div class="col">
          <label class="mb-0">Conversación Iniciada por un cliente (USD)</label>
        </div>
        <div class="col">
          <label class="mb-0">Duración de conversación sin costo</label>
        </div>
        <div class="col">
          <label class="mb-0">Conversación iniciada por la empresa (USD)</label>
        </div>
      </div>
      <div class="card_box">
        <div
          v-for="item in tableData"
          :key="item.id"
          class="row row-line"
        >
        <div class="col my-3">
          <p class="mb-0">{{ item.country }}</p>
        </div>
        <div class="col my-3">
          <p class="mb-0">{{ item.byClient }} UF</p>
        </div>
        <div class="col my-3">
          <p class="mb-0">{{ item.free }} Hrs.</p>
        </div>
        <div class="col my-3">
          <p class="mb-0">{{ item.byCorporate }} UF</p>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'show'
  ],
  data() {
    return {
      tableData: [
        {
          country: 'Chile',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.103,
        },
        {
          country: 'México',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.056,
        },
        {
          country: 'Colombia',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.027,
        },
        {
          country: 'Perú',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.085,
        },
        {
          country: 'Argentina',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.085,
        },
        {
          country: 'España',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.1,
        },
        {
          country: 'Uruguay',
          byClient: 0.01,
          free: 24,
          byCorporate: 0.09,
        },
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.whatsapp-table {
  margin: 4rem auto;

  .card {
    padding: 0;

    &_head {
      display: flex;
      align-items: center;
      padding: 1.5rem;
      background-color: #fafafa;
      border-radius: 1rem 1rem 0 0;
    }
    &_box {
      padding: .75rem 1.5rem;

      .row-line {
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
