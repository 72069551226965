<template>
  <div class="validate-defaulter"><span v-if="defaultersIDs">{{ showMessage() }}</span></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(["selected_bot"]),

    defaultersIDs() {
      return [
        // 926, // Chilecostura
        // 1134, // Indisa
        1525, // Danicolle
        1736, // Franchi
        // 934, // Subasta aduanera
        456, // QA
        7, // Test
        // 683, // Neourbe
        1104,
        1504 // petshop patitas
        // 1937 // techdent
      ].includes(this.selected_bot.id)
    }
  },
  methods: {
    showMessage() {
      this.$swal({
        icon: "warning",
        title: "Pago pendiente",
        html: `Notamos que hay diferencias en el pago de tu cuenta. Por favor, ponte en contacto al correo <span class="white">finanzas@databot.cl</span> para regularizar el servicio o tu cuenta será desactivada.<br><a class="btn btn-base mt-5" href="mailto:finanzas@databot.cl?subject=Regularizar%20pagos%20de%20cuenta%20Databot?body=Quiero%20regularizar%20mis%20pagos%20del%20servicio">Regularizar</a>`,
        showConfirmButton: false,
      });
      let modal_bg = document.getElementsByClassName("swal2-popup");
      if (modal_bg) modal_bg[0].classList.add('swal2-popup-2');
    }
  }
}
</script>

<style lang="scss">
.swal2-popup-2 {
  width: 70%;
  height: 70%;
  background-color: #F5365C;
  
  .swal2-title {
    color: #fff;
    font-size: 2.5rem;
  }
  .swal2-html-container {
    text-align: center;
    color: #fff;
    width: 60%;
    font-size: 1.2rem;
    margin: 0 auto;
  }
  .swal2-confirm {
    background-color: #181818 !important;
    border: 1px solid #181818 !important;
  }
  .swal2-icon.swal2-warning, .swal2-icon-content {
    border-color: #fff;
    color: #fff;
  }
}
</style>