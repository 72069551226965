<template>
  <section class="planes">
    <div class="my-5">
      <h1 class="planes__title text-center">¡Elije tu <span class="destacado">plan</span>!</h1>
      <BulletSteps :step="step" />
    </div>
    <PlanDinamico />
  </section>
</template>

<script>
import PlanDinamico from "./PlanDinamico";
import BulletSteps from "./BulletSteps";

export default {
  name: "register-planes",
  props: ["step"],

  components: {
    PlanDinamico,
    BulletSteps
  },

  created() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped>
.planes {
  width: 100%;

  &__title {
    font-size: 35px;
    margin-bottom: 0;
  }
}
</style>
