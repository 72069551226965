<template>
  <section class="planes" id="planes">
    <ValidatePlatform v-if="user" :user="user" />
    <SpinnerLoading v-if="loading" />
    <div v-else class="title_box">
      <h2 class="mb-0">
        Selecciona el plan {{ selected_bot ? "para " + selected_bot.bot_name : "" }}
      </h2>
      <div class="btn-box">
        <span
          class="text-state text-state_inactive"
          :class="period == 'yearly' ? '' : 'text-state_active'"
          @click="period = 'monthly'"
          ><i class="fas fa-eye my-0 mr-2" />Mensual
        </span>
        <span
          class="text-state text-state_inactive"
          :class="period == 'monthly' ? '' : 'text-state_active'"
          @click="period = 'yearly'"
          ><i class="fas fa-eye my-0 mr-2" />Anual
        </span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        v-for="(product, index) in products"
        :key="index"
        v-show="!show"
        class="card"
        :class="{ active_card: currentSub != null && currentSub.product == product.name }"
        id="planes-mensuales"
      >
        <span v-if="currentSub != null && currentSub.product == product.name" class="status">Plan actual</span>
        <PlanDinamicoContent
          :currentPlan="currentPlan"
          :currentSub="currentSub"
          :product="product"
          :period="period"
          :key="period"
          :img_route="items[index].img_url"
          :recomendation="items[index].recomendation"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import payment_api from "@/payment_api";
import "@/assets/buefy_class.scss";
import PlanDinamicoContent from "./PlanDinamicoContent";
import ValidatePlatform from "@/components/ValidatePlatform";

export default {
  components: {
    PlanDinamicoContent,
    ValidatePlatform
  },
  data() {
    return {
      loading: false,
      currentPlan: {},
      currentSub: {},
      show: false,
      products: [],
      channels: {},
      period: "",
      filteredPlans: {},
      items: [
        {
          img_url: "/img/icons/planes/6-gratis.svg",
          recomendation:
            "Cuenta con una pequeña capacidad de conversaciones. Ideal para uso personal."
        },
        {
          img_url: "/img/icons/planes/6.1-started.svg",
          recomendation: "Ideal para equipar tu emprendimiento y conocer los beneficios."
        },
        {
          img_url: "/img/icons/planes/6.2-pro.svg",
          recomendation: `<b style="text-decoration: underline;">¡Más solicitado!</b><br/>No pierdas posibles clientes y automatiza los procesos de un gran negocio.`
        },
        {
          img_url: "/img/icons/planes/6.3-corporate.svg",
          recomendation:
            "Si necesitas requerimientos especializados en tu área, nos ajustamos a tu medida."
        }
      ]
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getActualPlan(this.selected_bot);
    this.getProducts();
  },
  mounted() {
    // this.getProducts();
  },

  computed: {
    ...mapState(["user", "selected_bot", "platform_name"])
  },

  watch: {
    selected_bot(val) {
      this.getActualPlan(val);
      this.getProducts();
    }
  },

  methods: {
    async getActualPlan(bot) {
      // let resp = (await dashboard_api.get(`/client_subscriptions/by_bot_id/${bot.id}`)).data;
      let resp = (await payment_api.get(`/subscriptions/by_bot_id/${bot.id}`)).data;
      console.log('resp >', resp);
      this.currentSub = resp.subscription;
      if (this.currentSub != null) this.currentPlan = resp.subscription.plan;
    },

    async getProducts() {
      this.loading = true;
      let resp = (await payment_api.get("/products")).data;

      console.log(resp);
      this.products = resp.products;
      this.period = "monthly";
      this.loading = false;
    },

    channel(plan) {
      switch (plan.name) {
        case "Plan Starter":
          return this.channels.filter(item => item.name == "web4")[0];
        case "Plan Profesional":
          return this.channels.filter(item => item.name == "web1")[0];
        case "Plan Corporate":
          return this.channels.filter(item => item.name == "web2")[0];
        case "Plan Enterprise":
          return this.channels.filter(item => item.name == "web3")[0];
      }
    }
  }
};
</script>

<style lang="scss">
.planes {
  text-align: center;
  padding: 0 0.5rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding-top: 8rem;
  }
  @media (max-width: 640px) {
    padding-top: 5rem;
  }
  .btn-box {
    width: 20.5em;
    margin-right: 0;
    justify-content: flex-end;

    span {
      width: 100px;
      margin: 0 0.25rem;
      display: flex;
      align-items: center;
    }

    @media (max-width: 1600px) {
      width: 17em;
    }
    @media (max-width: 991px) {
      justify-content: center;
      margin: 1.25rem auto;
    }
  }

  .card {
    margin: 2.5rem auto;
    padding-bottom: 0;
    width: 22%;
    border: 1px solid #e8e8e8;
    background-color: transparent;
    padding: 1.5rem;

    @media (max-width: 1600px) {
      padding: 1rem;
      width: 18.7em;
    }
    &:hover {
      border: 1px solid #2981ef;
      background-color: #fff;

      .info_box {
        transition: 0.5s;
        background-color: #fff;
      }
    }
    .price {
      font-size: 2.25rem;
      line-height: 2.65rem;
      margin-bottom: 0;
      color: #2981ef;
    }
    .info_box {
      text-align: left;
      border-radius: 0.5rem;
      padding: 1rem;
    }
    .btn-base {
      margin: 1rem auto 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .plan_icon {
      width: 100px;
      margin: 2.5rem auto 1rem;
    }
    .before-price {
      text-decoration: line-through;
    }
  }
  .head-card {
    top: 0;
    right: 1rem;
    position: absolute;
  }
  .badge {
    width: 50px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: #2981ef;

    .star_circle {
      width: 25px;
      height: 25px;
      display: flex;
      color: #2981ef;
      font-size: 0.75rem;
      justify-content: center;
      align-items: center;
      border-radius: 5rem;
      margin-bottom: 0.5rem;
      background-color: #fff;
    }
  }
  .fa-question-circle {
    position: absolute;
    color: #181818;
    top: 1.5rem;
    right: 1.5rem;

    &:hover {
      color: #b2b2b2;
    }
  }
  .tooltip_text {
    width: 180px;
    top: 2rem;
    bottom: auto;
    left: auto;
    right: -2.25rem;
    text-align: left;

    &::after {
      border-color: transparent transparent #333333f2 transparent;
      top: -1.2rem;
      bottom: auto;
      left: auto;
      right: 2.25rem;
    }
  }
  .active_card {
    background-color: #fff;
    border-color: #2981ef !important;

    .status {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: fit-content;
    }
  }
  .text-state_inactive {
    border-color: transparent;
    color: #b2b2b2;
    cursor: pointer;

    &:hover {
      border-color: #2981ef;
      color: #2981ef;
    }
  }
  .text-state_active {
    border-color: #2981ef;
    color: #2981ef;
    cursor: pointer;
  }
}
</style>
