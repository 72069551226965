<template>
  <section class="registro" id="Comenzar">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6">
        <div class="row justify-content-center box">
          <div class="col-lg-8 pb-4">
            <h1 class="text-center mb-5">Crea tu chatbot en 1 minuto.</h1>
            <DataInput
              label="Nombre"
              type="text"
              :model="model.first_name"
              @newValue="model.first_name = $event"
              :error="error == 'invalid_name'"
              limit="15"
              required
            />
            <DataInput
              label="Apellido"
              type="text"
              :model="model.last_name"
              @newValue="model.last_name = $event"
              :error="error == 'invalid_lastname'"
              required
            />
            <DataInput
              label="Correo"
              type="email"
              :model="model.email"
              @newValue="model.email = $event"
              :error="error == 'invalid_mail'"
              required
            />
            <DataInput
              label="Teléfono"
              type="tel"
              :model="model.phone"
              @newValue="model.phone = $event"
              required
            />
            <DataInput
              label="Sitio web"
              type="url"
              :model="model.url"
              @newValue="model.url = $event"
              :error="error == 'invalid_url'"
              required
            />
            <DataInput
              label="Contraseña"
              type="password"
              :model="model.password"
              :error="error == 'invalid_pass'"
              min="6"
              @newValue="model.password = $event"
              @sendEnterEvent="register"
              required
            />
            <p class="text-center my-3">
              Al crear tu cuenta aceptas nuestros. <br>
              <span class="link click" @click="show_modal_terms = true">Términos y condiciones.</span>
            </p>
            <button
              class="btn btn-base second lg w-100 my-3"
              :disabled="loading"
              @click.prevent="register"
            >Crear cuenta</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img class="banner-1" src="/img/banners/banner-register-1.jpg" alt="">
      </div>
    </div>
    <Modal :show="show_modal_terms" @close="show_modal_terms = false">
      <template v-slot:content @close="show_modal_terms = false">
        <TermsAndConditions />
      </template>
    </Modal>
  </section>
</template>
<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import TermsAndConditions from "@/views/V2/Auth/components/TermsAndConditions";
import environment from "@/environment.js";
import chatbot_api from "@/chatbot_api.js";
import DataInput from "@/components/builders/DataInput";

export default {
  name: "FormRegistro",
  props: ["step", "type"],
  components: {
    TermsAndConditions,
    DataInput
  },
  data() {
    return {
      model: {
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        url: "",
        origin_ecommerce: {
          url_origin: "",
          access_token: "",
          welcome_instructions: false,
        },
        password: "",
        terms_confirmation: "",
      },
      loading: false,
      error: "",
      show_modal_terms: false,
      mailchimp: {
        register: { tags: ["lead-cuenta-creada"] },
        register_jumpseller: { tags: ["usuario-jumpseller"] },
      },
      gtm: {
        register: {
          sendTo: "AW-622614762/bZFqCPeGpfABEOqx8agC",
        },
      },
      urlStatus: true,
      token: "",
      platform: "", // jumpseller, shopify, etc
      shop_domain: "", // dominio del comercio
      newUser: {}
    };
  },
  computed: {
    ...mapState(["user", "platform_name", "platform_shop", "platform_access", "from_query"]),

    validEmail() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email);
    },
    validUrl() {
      // let re = /w{3}\.[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}/;
      let re = /(w{3}\.)?[a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}/;
      return re.test(this.model.url);
    },
  },
  methods: {
    ...mapMutations(["loginAction", "setRegisterProcess"]),

    getIntegrationtoken() {
      // for integrations
      if (this.$route.query.token_access) {
        this.token = this.$route.query.token_access;
      } else {
        axios
          .post(
            `https://accounts.jumpseller.com/oauth/token?grant_type=authorization_code&client_id=c7176f690639bb06f46480e2cb9e3ca4986c79df1bdb996ac41f9b758e62066b&client_secret=0f0e494b74843a0cbe135d387bbc350beaf7961d9053da84a76528716bc5dde7&code=${this.$route.query.code}&redirect_uri=https://ia.databot.cl/integration-register`
          )
          .then((response) => {
            this.token = response.data.access_token;
          });
      }
    },
    async checkUrl() {
      const instance = axios.create({ baseURL: environment.url_env_self });
      this.urlStatus = (await instance.get(`/urls/check?url=${this.model.url}`)).data;
    },
    async register() {
      const self = this;
      self.loading = true;

      const {
        name,
        first_name,
        last_name,
        email,
        phone,
        password,
        url,
        origin_ecommerce,
      } = self.model;

      if (self.platform_name === "shopify") {
        origin_ecommerce.url_origin = self.platform_shop;
        origin_ecommerce.access_token = self.platform_access;
        origin_ecommerce.welcome_instructions = true;
      }
      // Validaciones
      // await self.checkUrl();

      if (!first_name) {
        self.error = "invalid_name";
        self.loading = false;
      }
      else if (!last_name) {
        self.error = "invalid_lastname";
        self.loading = false;
      }
      else if (!self.validEmail) {
        self.error = "invalid_mail";
        self.loading = false;
      }
      // TODO validación de url comentada por campaña
      // else if (!self.validUrl || !self.urlStatus) {
      else if (!self.urlStatus) {
        self.error = "invalid_url";
        self.loading = false;
      }
      else if (!password || password.length < 6) {
        self.error = "invalid_pass";
        self.loading = false;
      }
      // # Validaciones
      else {
        self.$swal({
          icon: "info",
          title: "Estamos creando su cuenta...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        self.$swal.showLoading();
        self.error = "";
        self.loading = true;

        const authParams = {
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          email,
          phone: phone.toString().includes(" ") ? phone.replace(/\s/g, "") : phone,
          origin_ecommerce,
          url,
          password,
          password_confirmation: password
        };
        const campaign_url = this.from_query
          ? this.$route.query.from
            ? window.location.href
            : `${window.location.href}&from=${this.from_query}`
          : null;

        if (campaign_url) authParams.campaign_url = campaign_url;

        // Creo usuario
        dashboard_api
          .post("/auth", authParams)
          .then((result) => {
            // Enviado campo url para guardar en chatbot web
            self.$emit("sendChatbotWeb", url);
            self.newUser = result.data;

            let user = result.data;
            console.log("result user--->", result);
            if (result.status == 201) {
              // integracion MAILCHIMP, envio correo
              if (self.mailchimp[self.type]) {
                // console.log("mailchimp");
                axios.post(environment.socket_url + "/mailchimp/lists/5eb3f069ce/members", {
                  business: "Databot",
                  payload: {
                    email_address: email,
                    full_name: `${first_name} ${last_name}`,
                    status: "subscribed",
                    source: "Integration",
                    tags: self.mailchimp[self.type].tags,
                    merge_fields: {
                      FNAME: `${first_name} ${last_name}`,
                      ADDRESS: "",
                      PHONE: phone
                    }
                  }
                });
              }
              // agrego etiqueta gtm
              if (self.gtm[self.type]) {
                gtag("event", "conversion", {
                  send_to: self.gtm[self.type].sendTo,
                });
              }
              // Logeo al usuario
              // TODO Buscar la forma de devolver al usuario logueado al crear la cuenta y nos ahorramos este paso
              dashboard_api
                .post("/auth/sign_in", {
                  email,
                  password,
                })
                .then((response) => {
                  self.$store.commit("authModule/credentialsStorage", {
                    ...response.headers,
                  });
                  self.loginAction({
                    user,
                    token: response.headers["access-token"],
                    botIds: [],
                    url,
                  });
                  dashboard_api
                    .post("/mails/landing", {
                      name: `${first_name} ${last_name}`,
                      email,
                      phone,
                      mensaje: url,
                    })
                    .then((responseemail) => {
                      chatbot_api
                        .get("/getuser", {
                          params: {
                            email,
                          },
                        })
                        .then(async (getdatauser) => {
                          if (getdatauser.data.error) {
                            chatbot_api
                              .post("/sendlead", {
                                email,
                                name: `${first_name} ${last_name}`,
                                firstName: first_name,
                                lastName: last_name,
                                web: url,
                                phone,
                              })
                              .then(async (response) => {
                                // realiza la busqueda de la empresa
                                const getcompany = await self.getcompany();

                                if (getcompany.data.response.results != "") {
                                  // Agrega el lead a la empresa
                                  await self.addleadcompany(
                                    response,
                                    getcompany
                                  );

                                  await self.newbusiness(getcompany, response);
                                } else {
                                  // crea la empresa
                                  await self.createcompany();

                                  // realiza la busqueda de la empresa
                                  const getcompanycreate =
                                    await self.getcompany();

                                  if (
                                    getcompanycreate.data.response.results
                                      .length == 1
                                  ) {
                                    await self.addleadcompany(
                                      response,
                                      getcompanycreate
                                    );

                                    await self.newbusiness(
                                      getcompanycreate,
                                      response
                                    );
                                  } else {
                                    const companyresults =
                                      getcompanycreate.data.response.results;

                                    companyresults.forEach(async (element) => {
                                      if (element.properties.name) {
                                        if (
                                          element.properties.name.value == url
                                        ) {
                                          // eliminar compañia
                                          await self.deletebusiness(element);

                                          const getcompanycreate2 =
                                            await self.getcompany();

                                          await self.newbusiness(
                                            getcompanycreate2,
                                            response
                                          );
                                        }
                                      } else {
                                      }
                                      // end foreach
                                    });
                                  }
                                }
                              })
                              .catch((error) =>
                                console.log("errorrrrrrrrr =>", error)
                              );
                          } else {
                            const getcompany = await self.getcompany();

                            if (getcompany.data.response.results != "") {
                              // realiza el agregar
                              await self.addleadcompany(
                                getdatauser,
                                getcompany
                              );

                              await self.newbusiness(getcompany, getdatauser);
                            } else {
                              // crea la empresa
                              await self.createcompany();

                              const getcompanycreate = await self.getcompany();

                              if (
                                getcompanycreate.data.response.results.length ==
                                1
                              ) {
                                // realiza el agregar
                                await self.addleadcompany(
                                  getdatauser,
                                  getcompanycreate
                                );
                                // crea el negocio
                                await self.newbusiness(
                                  getcompanycreate,
                                  getdatauser
                                );
                              } else {
                                const companyresults =
                                  getcompanycreate.data.response.results;
                                companyresults.forEach(async (element) => {
                                  if (element.properties.name) {
                                    if (element.properties.name.value == url) {
                                      await self.deletebusiness(element);

                                      // realiza la busqueda de la empresa
                                      const getcompanycreate2 =
                                        await self.getcompany();

                                      // crea el negocio
                                      await self.newbusiness(
                                        getcompanycreate2,
                                        getdatauser
                                      );
                                    }
                                  }
                                });
                              }
                            }
                          }
                        });
                    })
                    .catch((error) => console.log("error =>", error));
                  
                  dashboard_api
                    .post("/flow_requests/create_customer", {
                      flow_request: {
                        name: `${first_name} ${last_name}`,
                        email,
                        externalId: user.id,
                      },
                    })
                    .then((flowResponse) => {
                      console.log('flowResponse->', flowResponse);

                      self.$swal({
                        icon: "success",
                        title: "¡Cuenta creada!",
                        timer: 1000,
                        showConfirmButton: false,
                      });
                      self.setRegisterProcess({
                        user: user.id,
                        step: 2,
                        customerId: flowResponse.data.customerId,
                      });
                      self.$router.push({
                        query: {
                          name: "primer-chatbot",
                          token_access: self.platform_access,
                          shop_domain: self.platform_shop,
                        },
                      });
                    })
                    .catch((error) => console.log(error));
                });
            }
          })
          .catch((err) => {
            self.loading = false;
            console.log("err -> ", err);
            self.$swal({
              icon: "error",
              title: "Verifica el correo ingresado",
            });
          });
      }
    },
    createcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/createcompany", {
            name: this.model.url,
          })
          .then(async (company) => {
            resolve(company);
          })
          .catch((error) => reject(error));
      });
    },

    getcompany() {
      return new Promise((resolve, reject) => {
        chatbot_api
          .get("/getcompany", {
            params: {
              domain: this.model.url,
            },
          })
          .then((getcompany) => {
            resolve(getcompany);
          })
          .catch((error) => reject(error));
      });
    },

    deletebusiness(element) {
      chatbot_api
        .delete("/deletebusiness", {
          params: {
            id: element.companyId,
          },
        })
        .then(async (responsse) => {});
    },

    addleadcompany(response, getcompany) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .put("/addleadcompany", {
            client: response.data.response.vid,
            company: getcompany.data.response.results[0].companyId,
          })
          .then((addcompany) => {
            resolve(addcompany);
          })
          .catch((error) => reject(error));
      });
    },

    newbusiness(getcompany, response) {
      return new Promise((resolve, reject) => {
        chatbot_api
          .post("/newbusiness", {
            companiid: getcompany.data.response.results[0].companyId,
            clientid: response.data.response.vid,
            domain: this.model.url,
            from: this.$route.name,
          })
          .then((business) => {
            if (
              business.data &&
              business.data.response &&
              business.data.response.dealId
            ) {
              // this.setDeal(business.data.response.dealId);
              dashboard_api.put("/relief/useredit", {
                user_id: this.newUser.id,
                hubspot_deal_id: business.data.response.dealId,
                hubspot_company_id:
                  getcompany.data.response.results[0].companyId,
                hubspot_client_id: response.data.response.vid,
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.registro {
  width: 100%;
  height: 100%;

  .btn-base {
    font-size: 1rem;
  }
  .box {
    @media (max-width: 768px) {
      max-width: 450px;
      margin: auto;
    }
  }
  .banner-1 {
    width: 100%;
    border-radius: 1.5rem;
  }
}
</style>
